import BaseStore from '../BaseStore';

export default class FAQStore extends BaseStore {
  state = {
    open: []
  };

  getters = {
    faqOpen: state => state.faqOpen
  };

  mutations = {
    FAQ_OPEN(state, topic) {
      state.open.push(topic.topicID);
    },
    FAQ_CLOSE(state, topic) {
      state.open = state.open.filter(t => t !== topic.topicID);
    }
  };

  actions = {
    openTopic: ({ commit }, topicID) => {
      commit('FAQ_OPEN', { topicID });
    },
    closeTopic: ({ commit }, topicID) => {
      commit('FAQ_CLOSE', { topicID });
    }
  }
}
