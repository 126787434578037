import BaseStore from '../BaseStore';
import _ from 'lodash';

export default class CourseStore extends BaseStore {
  state = {
    courses: null,
    projects: null,
    categories: null,
    timestamp: null
  };

  mutations = {
    CACHE_COURSE_INFO: (state, courseInfo) => {
      state.categories = [];
      courseInfo.courses.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      courseInfo.projects.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

      state.courses = courseInfo.courses;
      state.projects = courseInfo.projects;

      courseInfo.courses.filter(c => c.isActive || c.active).forEach(course => {
        course.categories.forEach((category) => this.checkAndAddCategories(state, category));
      });

      courseInfo.projects.forEach(project => {
        project.categories.forEach((category) => this.checkAndAddCategories(state, category));
      });

      state.timestamp = new Date().getTime();
    },
    CACHE_SPEAKER_COURSES: (state, courseInfo) => {
      courseInfo.courses.forEach(course => {
        course.isSpeaker = true;
      });
      if (state.courses) {
        state.courses = [...courseInfo.courses, ...state.courses];
      } else {
        state.courses = [...courseInfo.courses];
      }
    },
    CLEAR_COURSE_INFO: (state) => {
      state.project = null;
      state.courses = null;
      state.categories = null;
      state.timestamp = null;
    },
    SET_COURSE_LOADING: (state, payload) => {
      if (state.courses !== null) {
        let course;
        if (payload.isInProject) {
          state.projects.forEach(project => {
            if (!course) {
              course = project.courses.find(c => {
                return c.id === payload.courseId;
              });
            }
          });
        } else {
          course = state.courses.find(c => c.id === payload.courseId);
        }

        if (course) {
          course.isLoading = true;
        }
      }
    },
    REPLACE_COURSE_INFO: (state, payload) => {
      if (state.courses !== null) {
        if (payload.isInProject) {
          let course;
          state.projects.forEach((project, projectIndex) => {
            if (!course) {
              course = project.courses.find(c => c.id === payload.courseId);
              if (course) {
                const index = project.courses.indexOf(course);
                state.projects[projectIndex].courses[index] = payload.courseData;
              }
            }
          });
        } else {
          const course = state.courses.find(c => c.id === payload.courseId);
          const index = state.courses.indexOf(course);
          state.courses[index] = payload.courseData;
        }
      }
    }
  };

  checkAndAddCategories(state, category) {
    if (state.categories === null) {
      state.categories = [];
    }

    if (state.categories.find(c => c.id === category.id) === undefined) {
      if (category.title !== undefined) {
        state.categories.push(category);
      }
    }
  }

  getters = {
    courses: state => _.cloneDeep(state.courses),
    projects: state => _.cloneDeep(state.projects),
    state: state => _.cloneDeep(state),
    categories: state => _.cloneDeep(state.categories)
  };

  actions = {
    cacheCourseInfo: ({ commit }, courseInfo) => {
      commit('CACHE_COURSE_INFO', courseInfo);
    },
    cacheSpeakerCourseInfo: ({ commit }, courseInfo) => {
      commit('CACHE_SPEAKER_COURSES', courseInfo);
    },
    clearCache: ({ commit }) => {
      commit('CLEAR_COURSE_INFO');
    },
    setCourseLoading: ({ commit }, payload) => {
      commit('SET_COURSE_LOADING', payload);
    },
    replaceCourseInfo: ({ commit }, payload) => {
      commit('REPLACE_COURSE_INFO', payload);
    }
  }
}
