import CustomizingLoader from '@/utils/CustomizingLoader';
import Auth from '@/middleware/auth';
import { lazyLoading } from '@/utils/helpers';

export const Contact = {
  path: '/contact',
  component: () => CustomizingLoader('views/layout/pages/Layout'),
  showInMenu: false,
  meta: {
    label: 'main_navigation.contact',
    middleware: Auth
  },
  children: [{
    path: '',
    name: 'contact',
    component: lazyLoading('contact'),
    meta: {
      label: 'main_navigation.contact',
      middleware: Auth
    }
  }]
};
