import Vue from 'vue';
import Vuex from 'vuex';
import MandantStore from './mandant/MandantStore';
import UserStore from './user/UserStore';
import createPersistedState from 'vuex-persistedstate';
import CourseStore from './course/CourseStore';
import UIStore from './ui/UIStore';
import DetailStore from './detail/DetailStore';
import SettingsStore from './settings/SettingsStore';
import FAQStore from './faq/FAQStore';
import CourseBookingStore from './course/CourseBookingStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    mandantStore: new MandantStore(),
    userStore: new UserStore(),
    courseStore: new CourseStore(),
    courseBookingStore: new CourseBookingStore(),
    uiStore: new UIStore(),
    detailStore: new DetailStore(),
    settingsStore: new SettingsStore(),
    FAQStore: new FAQStore()
  },
  plugins: [createPersistedState({
    paths: ['mandantStore', 'userStore', 'courseStore']
  })]
});
