import BaseStore from '../BaseStore';

export default class DetailStore extends BaseStore {
  state = {
    comesFromCatalog: false
  };

  mutations = {
    SET_COMES_FROM_CATALOG: (state, catalogState) => {
      state.comesFromCatalog = catalogState;
    }
  };

  getters = { };

  actions = {
    setComesFromCatalog: ({ commit }, catalogState) => {
      commit('SET_COMES_FROM_CATALOG', catalogState);
    }
  }
}
