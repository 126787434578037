import CustomizingLoader from '@/utils/CustomizingLoader';

export const Preview = {
  path: '/preview/:component/:id',
  component: () => CustomizingLoader('views/preview/pages/Index'),
  showInMenu: false,
  meta: {
    label: 'main_navigation.profile'
  }
};
