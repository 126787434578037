import { lazyLoading } from '@/utils/helpers';
import CustomizingLoader from '@/utils/CustomizingLoader';
import Auth from '@/middleware/auth';

export const Catalog = {
  path: '/catalog',
  component: () => CustomizingLoader('views/layout/pages/Layout'),
  showInMenu: true,
  menuHideOn: {
    prop: 'catalogEnabled',
    is: false
  },
  meta: {
    label: 'main_navigation.catalog',
    middleware: Auth
  },
  children: [{
    path: '',
    name: 'catalog',
    component: lazyLoading('catalog'),
    meta: {
      label: 'main_navigation.catalog',
      middleware: Auth
    }
  }, {
    path: ':id/courses',
    name: 'courseCatalogDetail',
    component: lazyLoading('catalog', 'CatalogCourseList'),
    meta: {
      middleware: Auth
    }
  }, {
    path: ':id/calendar',
    name: 'catalogCalendar',
    component: lazyLoading('catalog', 'Calendar'),
    meta: {
      middleware: Auth
    }
  }]
};
