import BaseStore from '../BaseStore';

export default class SettingsStore extends BaseStore {
  state = {
    settings: null
  };

  mutations = {
    FILL_SETTINGS: (state, settings) => {
      state.settings = settings;
    }
  };

  getters = { };

  actions = {
    fillSettings: ({ commit }, settings) => {
      commit('FILL_SETTINGS', settings);
    }
  }
}
