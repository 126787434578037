import CustomizingLoader from './CustomizingLoader';

// lazy loading Components
const lazyLoading = function(viewFolder, vueFile = 'Index') {
  return () => CustomizingLoader(`views/${viewFolder}/pages/${vueFile}`);
};

export {
  lazyLoading
};
