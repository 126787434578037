import ShitExplorerDetector from './ShitExplorerDetector';
import Config from '../config';

export const VueLogger = {
  data() {
    return {
      logger: undefined
    };
  },
  mounted() {
    this.logger = new Logger(this);
  }
};

export class Logger {
  constructor(component) {
    if (component.$options) {
      this.name = component.$options.name;
      this.isVueComponent = true;
    } else {
      this.name = component;
      this.isVueComponent = false;
    }
  }

  styling(color) {
    return 'font-family: Arial,sans-serif;color: #fff; border-radius: 4px;background-color: ' + color + ';padding: 2px 8px; font-size: 10px';
  }

  info(message, logLevel) {
    this.log(LogType.INFO, message, logLevel);
  }

  error(message, logLevel) {
    this.log(LogType.ERROR, message, logLevel);
  }

  warning(message, logLevel) {
    this.log(LogType.WARNING, message, logLevel);
  }

  debug(message, logLevel) {
    this.log(LogType.DEBUG, message, logLevel);
  }

  log(type, message, logLevel) {
    let currentLogLevel = Config.logLevel;

    if (window.temporaryLogLevel) {
      currentLogLevel = window.temporaryLogLevel;
    }

    if (currentLogLevel >= (logLevel || type.level)) {
      if (ShitExplorerDetector.detect()) {
        // eslint-disable-next-line no-console
        console.log(new Date().toLocaleString() + '[' + type.name.toUpperCase() + '] [' + this.logName() + ']:', message);
      } else {
        // eslint-disable-next-line no-console
        console.log(new Date().toLocaleString() + ' %c' + type.name.toUpperCase(), this.styling(type.color), '[' + this.logName() + ']:', message);
      }
    }
  }

  logName() {
    if (this.isVueComponent) {
      return 'Vue.Component.' + this.name;
    }

    return this.name;
  }
}

export class LogLevel {
  constructor(name, level, color) {
    this.name = name;
    this.level = level;
    this.color = color;
  }
}

export const LogType = {
  INFO: new LogLevel('INFO', 2, '#007de7'),
  ERROR: new LogLevel('ERROR', 1, '#e70000'),
  WARNING: new LogLevel('WARNING', 3, '#e77f00'),
  DEBUG: new LogLevel('DEBUG', 7, '#4a10fd')
};
