import {VueLogger} from "@/utils/Logger";

export const AppUpdate = {
  data() {
    return {
      // refresh variables
      refreshing: false
    };
  },
  mixins: [VueLogger],
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.refreshApp, { once: true });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      this.logger.info('Updated ServiceWorker started! Refreshing page to show the updated version to the user...', 0);
      window.location.reload();
    });
  },

  methods: {
    // Called when the user accepts the update
    refreshApp(event) {
      const registration = event.detail;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!registration || !registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.logger.info('Registering ServiceWorker from downloaded update...', 0);
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
};
