export default class ShitExplorerDetector {
  static detect() {
    const userAgent = window.navigator.userAgent;

    let msie = userAgent.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10);
    }

    let trident = userAgent.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      let rv = userAgent.indexOf('rv:');
      return parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10);
    }

    return false;
  }
}
