import CustomizingLoader from '@/utils/CustomizingLoader';
import { lazyLoading } from '@/utils/helpers';

export const LoginRoutes = {
  path: '/',
  component: () => CustomizingLoader('views/layout/pages/LayoutLogin'),
  showInMenu: false,
  meta: {
    label: 'main_navigation.login'
  },
  children: [{
    path: '',
    name: 'login',
    showInMenu: false,
    component: lazyLoading('login'),
    meta: {
      label: 'main_navigation.login'
    }
  }, {
    path: '/registration',
    name: 'registration',
    showInMenu: false,
    component: lazyLoading('registration'),
    meta: {
      label: 'main_navigation.registration'
    }
  }, {
    path: '/password-lost',
    name: 'password-lost',
    showInMenu: false,
    component: lazyLoading('password-lost'),
    meta: {
      label: 'main_navigation.login'
    }
  }, {
    path: '/forgotPassword',
    name: 'forgotPassword',
    showInMenu: false,
    component: lazyLoading('password-lost', 'ResetPassword'),
    meta: {
      label: 'main_navigation.login'
    }
  }, {
    path: '/resetWithSecurityQuestion',
    name: 'resetWithSecurityQuestion',
    showInMenu: false,
    component: lazyLoading('password-lost',
      'ResetPasswordWithSecurityQuestion'),
    meta: {
      label: 'main_navigation.login'
    }
  }
  ]
};
