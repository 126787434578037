import BaseStore from '../BaseStore';

export default class UserStore extends BaseStore {
  state = {
    language: null,
    name: '',
    lastVisitedPage: '',
    lastVisitedPagePath: '',
    actor: null
  };

  mutations = {
    USER_INFO: (state, payload) => {
      Object.assign(state, payload);
    },
    LANGUAGE: (state, payload) => {
      state.language = payload.language;
    },
    SET_ACTOR_ID: (state, payload) => {
      if (!state.actor) {
        state.actor = {};
      }
      state.actor.id = payload;
    },
    NAME: (state, payload) => {
      state.name = payload.name;
    },
    LAST_VISITED_PAGE: (state, payload) => {
      state.lastVisitedPage = payload.lastVisitedPage;
    },
    LAST_VISITED_PAGE_PATH: (state, payload) => {
      state.lastVisitedPagePath = payload.lastVisitedPagePath;
    }
  };

  getters = {
    language: state => state.language,
    name: state => state.name,
    lastVisitedPage: state => state.lastVisitedPage
  };

  actions = {
    setActorId: ({ commit }, payload) => {
      commit('SET_ACTOR_ID', payload);
    },
    setUserInfo: ({ commit }, payload) => {
      commit('USER_INFO', payload);
    },
    setLanguage: ({ commit }, language) => {
      commit('LANGUAGE', {
        language
      });
    },
    setName: ({ commit }, name) => {
      commit('NAME', {
        name
      });
    },
    setLastVisitedPage: ({ commit }, lastVisitedPage) => {
      commit('LAST_VISITED_PAGE', {
        lastVisitedPage
      });
    },
    setLastVisitedPagePath: ({ commit }, lastVisitedPagePath) => {
      commit('LAST_VISITED_PAGE_PATH', {
        lastVisitedPagePath
      });
    }
  }
}
