import CustomizingLoader from '@/utils/CustomizingLoader';
import Auth from '@/middleware/auth';
import { lazyLoading } from '@/utils/helpers';

export const LearningProfile = {
  path: '/learningProfile',
  component: () => CustomizingLoader('views/layout/pages/Layout'),
  showInMenu: true,
  meta: {
    label: 'main_navigation.learningProfile',
    middleware: Auth
  },
  children: [{
    path: '',
    name: 'learningProfile',
    component: lazyLoading('learningProfile'),
    meta: {
      label: 'main_navigation.learningProfile',
      middleware: Auth
    }
  }]
};
