<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
    <OfflineModal/>
  </div>
</template>

<script>
import configs from './config';
import {LanguageUtil} from './utils/LanguageUtil';
import {TokenValidator} from 'am-api-connector-general';
import {AppUpdate} from "@/utils/mixins/AppUpdate";
import CustomizingLoader from "@/utils/CustomizingLoader";

export default {
  name: 'App',
  components: {
    OfflineModal: () => CustomizingLoader('components/modal/OfflineModal')
  },
  mixins: [LanguageUtil, AppUpdate],
  mounted() {
    const mandantInfo = this.$store.state.mandantStore;
    if (mandantInfo.header !== undefined && mandantInfo.header.windowTitle !== '' && mandantInfo.masterlanguage !== ''
      && mandantInfo.header.windowTitle[mandantInfo.masterlanguage] !== undefined) {
      document.title = this.translator.getLanguageValue(mandantInfo.header.windowTitle, this.$i18n.locale);
    } else {
      document.title = configs.windowTitle;
    }
    this.$services.getSettingsService().getSettings().then((settings) => {
      this.$store.dispatch('settingsStore/fillSettings', settings);
    });
    TokenValidator.isValid(configs.host + '/academies/api', () => {
    }).then(result => {
      if (result === true) {
        if (this.$route.query && this.$route.query.ref) {
          this.$router.push(this.$route.query.ref);
        }
      }
    });
  },
  watch: {
    '$route.query': function() {
      if (this.$route.query && this.$route.query.ref) {
        this.$router.push(this.$route.query.ref);
      }
    }
  }
};
</script>

<style lang="less">
@import 'assets/less/_all.less';
@import (optional) 'customizing/assets/less/_all.less';
</style>
