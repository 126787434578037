/* eslint-disable no-console */

import { register } from 'register-service-worker';
import {Logger} from './utils/Logger';

const logger = new Logger("ServiceWorker");

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      logger.info(
        'Academy is being served from cache by a service worker.\n',
        -1
      );
    },
    registered() {
      logger.info('Service worker has been registered.', -1);
    },
    cached() {
      logger.info('Content was cached for offline use!', -1);
    },
    updatefound() {
      logger.info('An update was found! Downloading update from server...', -1);
    },
    updated(registration) {
      logger.info('Update downloaded successfully! Starting update process...', -1);
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline() {
      logger.info('No internet connection found. App is running in offline mode.', -1);
    },
    error(error) {
      logger.error('Error during service worker registration:' + error.message, -1);
    }
  });
}
