import CustomizingLoader from '@/utils/CustomizingLoader';
import { lazyLoading } from '@/utils/helpers';

export const UserNotFound = {
  path: '/user-not-found',
  component: () => CustomizingLoader('views/layout/pages/LayoutNotFound'),
  showInMenu: false,
  children: [{
    path: '',
    name: 'user-not-found',
    component: lazyLoading('not-found', 'SSOUserNotFound')
  }]
};

export const NotFound = {
  path: '*',
  component: () => CustomizingLoader('views/layout/pages/LayoutNotFound'),
  showInMenu: false,
  children: [{
    path: '',
    name: 'not-found',
    component: lazyLoading('not-found')
  }]
};
