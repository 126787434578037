import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messagesDeDe from './messages.de_DE';
import messagesEnUs from './messages.en_US';
import messagesEsES from './messages.es_ES';
import messagesFrFR from './messages.fr_FR';
import messagesItIT from './messages.it_IT';
import messagesJaJA from './messages.ja_JA';
import messagesKoKO from './messages.ko_KO';
import messagesRuRU from './messages.ru_RU';
import messagesPlPL from './messages.pl_PL';
import messagesCsCZ from './messages.cs_CZ';
import messagesNlNL from './messages.nl_NL';
import messagesElEL from './messages.el_EL';
import messagesHuHU from './messages.hu_HU';
import messagesLtLT from './messages.lt_LT';
import messagesPtPT from './messages.pt_PT';
import messagesRoRO from './messages.ro_RO';
import messagesSlSL from './messages.sl_SL';
import messagesZhZH from './messages.zh_ZH';
import messagesBzBZ from './messages.bz_BZ';
import messagesTrTR from './messages.tr_TR';
import messagesSkSK from './messages.sk_SK';
import messagesbgBG from './messages.bg_BG';
import messagessrSR from './messages.sr_SR';
import messagesHrHR from './messages.hr_HR';
import messagesUkUK from './messages.uk_UK';
import messagesDaDA from './messages.da_DA';
import moment from 'moment';
import config from '../config';
import _ from 'lodash';
import { getBrowserLanguage } from '../utils/LanguageDeterminer';

Vue.use(VueI18n);
moment.locale(['en', 'de', 'es', 'fr', 'it', 'pl', 'ja', 'ko', 'ru', 'cs', 'nl', 'el', 'hu', 'lt', 'pt', 'ro', 'sl', 'zh', 'bz', 'tr', 'sk', 'bg', 'sr', 'hr', 'uk', 'da']);

const MOMENT_TRANSLATION_MAPPING = {
  'de_DE': 'de',
  'en_US': 'en',
  'es_ES': 'es',
  'fr_FR': 'fr',
  'it_IT': 'it',
  'ja_JA': 'ja',
  'ko_KO': 'ko',
  'ru_RU': 'ru',
  'pl_PL': 'pl',
  'cs_CZ': 'cs',
  'nl_NL': 'nl',
  'el_EL': 'el',
  'hu_HU': 'hu',
  'lt_LT': 'lt',
  'pt_PT': 'pt',
  'ro_RO': 'ro',
  'sl_SL': 'sl',
  'zh_ZH': 'zh',
  'bz_BZ': 'bz',
  'tr_TR': 'tr',
  'sk_SK': 'sk',
  'bg_BG': 'bg',
  'sr_SR': 'sr',
  'hr_HR': 'hr',
  'uk_UK': 'uk',
  'da_DA': 'da'
};

if (config.customizing !== 'x-cell') {
  try {
    const messagesDeDeCustom = require('../customizing/i18n/messages.de_DE.js');
    _.merge(messagesDeDe, messagesDeDeCustom);
  } catch (e) {
  }

  try {
    const messagesEnUSCustom = require('../customizing/i18n/messages.en_US.js');
    _.merge(messagesEnUs, messagesEnUSCustom);
  } catch (e) {
  }

  try {
    const messagesEsEsCustom = require('../customizing/i18n/messages.es_ES.js');
    _.merge(messagesEsES, messagesEsEsCustom);
  } catch (e) {
  }

  try {
    const messagesFrFRCustom = require('../customizing/i18n/messages.fr_FR.js');
    _.merge(messagesFrFR, messagesFrFRCustom);
  } catch (e) {
  }

  try {
    const messagesItITCustom = require('../customizing/i18n/messages.it_IT.js');
    _.merge(messagesItIT, messagesItITCustom);
  } catch (e) {
  }

  try {
    const messagesPlPLCustom = require('../customizing/i18n/messages.pl_PL.js');
    _.merge(messagesPlPL, messagesPlPLCustom);
  } catch (e) {
  }

  try {
    const messagesJaJACustom = require('../customizing/i18n/messages.ja_JA.js');
    _.merge(messagesJaJA, messagesJaJACustom);
  } catch (e) {
  }

  try {
    const messagesKoKOCustom = require('../customizing/i18n/messages.ko_KO.js');
    _.merge(messagesKoKO, messagesKoKOCustom);
  } catch (e) {
  }

  try {
    const messagesRuRUCustom = require('../customizing/i18n/messages.ru_RU.js');
    _.merge(messagesRuRU, messagesRuRUCustom);
  } catch (e) {
  }

  try {
    const messagesCsCZCustom = require('../customizing/i18n/messages.cs_CZ.js');
    _.merge(messagesCsCZ, messagesCsCZCustom);
  } catch (e) {
  }

  try {
    const messagesNlNLCustom = require('../customizing/i18n/messages.nl_NL.js');
    _.merge(messagesNlNL, messagesNlNLCustom);
  } catch (e) {
  }

  try {
    const messagesElELCustom = require('../customizing/i18n/messages.el_EL.js');
    _.merge(messagesElEL, messagesElELCustom);
  } catch (e) {
  }

  try {
    const messagesHuHUCustom = require('../customizing/i18n/messages.hu_HU.js');
    _.merge(messagesHuHU, messagesHuHUCustom);
  } catch (e) {
  }

  try {
    const messagesLtLTCustom = require('../customizing/i18n/messages.lt_LT.js');
    _.merge(messagesLtLT, messagesLtLTCustom);
  } catch (e) {
  }

  try {
    const messagesPtPTCustom = require('../customizing/i18n/messages.pt_PT.js');
    _.merge(messagesPtPT, messagesPtPTCustom);
  } catch (e) {
  }

  try {
    const messagesRoROCustom = require('../customizing/i18n/messages.ro_RO.js');
    _.merge(messagesRoRO, messagesRoROCustom);
  } catch (e) {
  }

  try {
    const messagesSlSLCustom = require('../customizing/i18n/messages.sl_SL.js');
    _.merge(messagesSlSL, messagesSlSLCustom);
  } catch (e) {
  }

  try {
    const messagesZhZHCustom = require('../customizing/i18n/messages.zh_ZH.js');
    _.merge(messagesZhZH, messagesZhZHCustom);
  } catch (e) {
  }

  try {
    const messagesBzBZCustom = require('../customizing/i18n/messages.bz_BZ.js');
    _.merge(messagesBzBZ, messagesBzBZCustom);
  } catch (e) {
  }

  try {
    const messagesTrTRCustom = require('../customizing/i18n/messages.tr_TR.js');
    _.merge(messagesTrTR, messagesTrTRCustom);
  } catch (e) {
  }

  try {
    const messagesSkSKCustom = require('../customizing/i18n/messages.sk_SK.js');
    _.merge(messagesSkSK, messagesSkSKCustom);
  } catch (e) {
  }

  try {
    const messagesBgBGCustom = require('../customizing/i18n/messages.bg_BG.js');
    _.merge(messagesbgBG, messagesBgBGCustom);
  } catch (e) {
  }

  try {
    const messagesSrSRCustom = require('../customizing/i18n/messages.sr_SR.js');
    _.merge(messagessrSR, messagesSrSRCustom);
  } catch (e) {
  }

  try {
    const messagesHrHRCustom = require('../customizing/i18n/messages.hr_HR.js');
    _.merge(messagesHrHR, messagesHrHRCustom);
  } catch (e) {
  }

  try {
    const messagesUkUKCustom = require('../customizing/i18n/messages.uk_UK.js');
    _.merge(messagesUkUK, messagesUkUKCustom);
  } catch (e) {
  }

  try {
    const messagesDaDACustom = require('../customizing/i18n/messages.da_DA.js');
    _.merge(messagesDaDA, messagesDaDACustom);
  } catch (e) {
  }
}

const i18n = new VueI18n({
  messages: {
    'de_DE': messagesDeDe,
    'de': messagesDeDe,
    'en_US': messagesEnUs,
    'en': messagesEnUs,
    'es_ES': messagesEsES,
    'es': messagesEsES,
    'fr_FR': messagesFrFR,
    'fr': messagesFrFR,
    'it_IT': messagesItIT,
    'it': messagesItIT,
    'pl_PL': messagesPlPL,
    'pl': messagesPlPL,
    'ja_JA': messagesJaJA,
    'ja': messagesJaJA,
    'ko_KO': messagesKoKO,
    'ko': messagesKoKO,
    'ru_RU': messagesRuRU,
    'ru': messagesRuRU,
    'cs_CZ': messagesCsCZ,
    'cs': messagesCsCZ,
    'nl_NL': messagesNlNL,
    'nl': messagesNlNL,
    'el_EL': messagesElEL,
    'el': messagesElEL,
    'hu_HU': messagesHuHU,
    'hu': messagesHuHU,
    'lt_LT': messagesLtLT,
    'lt': messagesLtLT,
    'pt_PT': messagesPtPT,
    'pt': messagesPtPT,
    'ro_RO': messagesRoRO,
    'ro': messagesRoRO,
    'sl_SL': messagesSlSL,
    'sl': messagesSlSL,
    'zh_ZH': messagesZhZH,
    'zh': messagesZhZH,
    'bz_BZ': messagesBzBZ,
    'bz': messagesBzBZ,
    'tr_TR': messagesTrTR,
    'tr': messagesTrTR,
    'sk_SK': messagesSkSK,
    'sk': messagesSkSK,
    'bg_BG': messagesbgBG,
    'bg': messagesbgBG,
    'sr_SR': messagessrSR,
    'sr': messagessrSR,
    'hr_HR': messagesHrHR,
    'hr': messagesHrHR,
    'uk_UK': messagesUkUK,
    'uk': messagesUkUK,
    'da_DA': messagesDaDA,
    'da': messagesDaDA
  },
  locale: getBrowserLanguage(),
  fallbackLang: getBrowserLanguage()
});

const i18nMixin = {
  computed: {
    language() {
      return this.$store.getters['userStore/language'];
    }
  },
  watch: {
    language(language, oldLanguage) {
      if (language !== oldLanguage) {
        this.$i18n.locale = language;
        moment.locale(MOMENT_TRANSLATION_MAPPING[language]);
      }
    }
  },
  created() {
    this.$i18n.locale = this.language;
    moment.locale(MOMENT_TRANSLATION_MAPPING[this.language]);
  }
};

export {
  i18n,
  i18nMixin
};
