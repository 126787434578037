import CustomizingLoader from '@/utils/CustomizingLoader';
import Auth from '@/middleware/auth';
import { lazyLoading } from '@/utils/helpers';

export const FAQ = {
  path: '/faq',
  component: () => CustomizingLoader('views/layout/pages/Layout'),
  showInMenu: true,
  menuHideOn: {
    prop: 'faqEnabled',
    is: false
  },
  meta: {
    label: 'main_navigation.faq',
    middleware: Auth
  },
  children: [{
    path: '',
    name: 'faq',
    component: lazyLoading('faq'),
    meta: {
      label: 'main_navigation.faq',
      middleware: Auth
    }
  }]
};
