import BaseStore from '../BaseStore';

export default class MandantStore extends BaseStore {
  state = {
    header: {}
  };

  mutations = {
    STORE_MANDANT_INFO: (state, mandantInfo) => {
      Object.assign(state, mandantInfo);
    }
  };

  getters = {
    mandantInfo: state => state
  };

  actions = {
    storeMandantInfo: ({ commit }, mandantInfo) => {
      commit('STORE_MANDANT_INFO', mandantInfo);
    }
  }
}
