module.exports = {
  "pages": {
    "profile": {
      "city": "Postnummer/by",
      "userdata": "Brugeroplysninger",
      "strasse": "Gade/nr.",
      "genderLab": "Tiltale",
      "required": "Skal udfyldes",
      "newPasswordRep": "Gentagelse",
      "position": "Position",
      "phone": "Telefon",
      "password": "Password",
      "oldPassword": "Gammelt password",
      "gender": {
        "unknown": "Ingen oplysninger",
        "woman": "Fru",
        "men": "Hr.",
        "diverse": "Divers"
      },
      "newPassword": "Nyt password",
      "lastname": "Efternavn",
      "language": "Sprog",
      "firstname": "Fornavn",
      "fax": "Fax",
      "email": "E-mail",
      "department": "Afdeling",
      "company": "Virksomhed"
    },
    "courseDetail": {
      "reserve": {
        "prebooked": {
          "text": "Du har tilkendegivet din interesse i denne træning."
        },
        "title": "Terminen/terminerne passer dig ikke?",
        "button": "Tilkendegiv interesse",
        "text": "Tilkendegiv din interesse. Så informeres du om mulige nye terminer."
      },
      "bookingInformation": {
        "weekdays": {
          "0": "Man.",
          "1": "Tirs.",
          "2": "Ons.",
          "3": "Tors.",
          "4": "Fre.",
          "5": "Lør.",
          "6": "Søn."
        },
        "trainNumber": "Tognummer",
        "arrivalType": {
          "train": "Tog",
          "none": "Ingen oplysninger",
          "car": "Auto",
          "plane": "Fly"
        },
        "placeholder": {
          "to": "til",
          "from": "af",
          "destination_trainstation": "Mål banegård",
          "destination_car": "Mål by",
          "destination_airport": "Mål lufthavn",
          "departure_trainstation": "Afrejse banegård",
          "departure_car": "Afrejse by",
          "departure_airport": "Afrejse lufthavn"
        },
        "months": {
          "0": "Januar",
          "1": "Februar",
          "2": "Marts",
          "3": "April",
          "4": "Maj",
          "5": "Juni",
          "6": "Juli",
          "7": "August",
          "8": "September",
          "9": "Oktober",
          "10": "November",
          "11": "December"
        },
        "note": "Anmærkning",
        "railwayStation": "Banegård",
        "food": "Forplejningspræference",
        "invoiceRecipient": "Regningsmodtager",
        "flightNumber": "Flynummer",
        "departureDate": "Afrejsedato",
        "departure": "Afrejse",
        "accounts": "Budgetenhed",
        "title": "Bookinginformation",
        "invoiceAddress": "Fakturaadresse",
        "arrivalDate": "Henrejsedato",
        "arrival": "Henrejse"
      },
      "webinar": {
        "state": {
          "beforeStart": "Webinar starter den ",
          "ended": "Webinar er afsluttet"
        },
        "host": "Åbn webinar"
      },
      "webinarLight": "Webinar oplysninger",
      "waitlist": "VENTELISTE",
      "expiry": "gyldig indtil",
      "sidebar": {
        "contentStatus": "Træningsstatus",
        "to": "til",
        "totalStatus": "Samlet status",
        "price": "Pris pr. pers.",
        "duration": "Læringstid",
        "status": "Bookingstatus",
        "numberOfParticipants": "Deltagerantal"
      },
      "classroomNo": "Skolingsnummer",
      "location": "Skolingssted",
      "title": "Træningsdetaljer",
      "classroomContent": "Skolingsindhold",
      "speaker": "Træner/censor",
      "targetGroup": "Målgruppe",
      "webinarSpeaker": "Censor",
      "requested": "FORESPURGT",
      "progress": "Forløb",
      "specials": "Særpræg",
      "noMeeting": "Der er endnu ingen termin for denne træning, eller også er alle terminer allerede udløbet.",
      "links": "Links",
      "fullyBooked": "BOOKET OP",
      "freeDayInfo": "Træningsfrie dage vises ikke",
      "freeDay": "Fridag",
      "edit": "Bearbejd",
      "downloads": {
        "modalHeadline": "Downloads",
        "sidebarLink": "Dokument(er)"
      },
      "docsAndLinks": "Dokumenter og links",
      "deadline": "Tilmeldingsstop",
      "stornoDeadline": "Annulleringsstop",
      "meeting": "Termin",
      "meetings": "Terminer",
      "contactPerson": "Kontaktperson",
      "stornoConditions": "Annulleringsbetingelser",
      "bonusPoints": "Bonuspoint",
      "back": "Tilbage til listen"
    },
    "login": {
      "password-reset": {
        "success": "Dit password er blevet ændret.",
        "error": "De indtastede passwords stemmer ikke overens.",
        "description": "Vælg et nyt password til din brugerkonto."
      },
      "accountBlocked": "Din konto er blevet spærret. Henvend dig til en ansvarlig medarbejder.",
      "error": "Forkert brugernavn og/eller password.",
      "errorForgotSecurityQuestion": "Svaret er desværre ikke korrekt.",
      "button": "Anmod om adgangsoplysninger",
      "success": "Forespørgslen er blevet sendt.",
      "empty": "Udfyld alle felterne.",
      "description": "Angiv dit brugernavn eller din e-mailadresse for at få tilsendt adgangsoplysningerne på ny.",
      "errorForgot": "Det var ikke muligt at finde en bruger, der stemmer overens med de oplyste kriterier.",
      "invalidPassword": "Ugyldigt log-in. Du har stadig #0 forsøg tilbage til at indtaste det rigtige password.",
      "invalidUser": "Ugyldigt log-in. Forkert brugernavn/password.",
      "back": "Tilbage til log-in",
      "placeholder": " Brugernavn/e-mailadresse"
    },
    "userNotFound": {
      "text": "Det var ikke muligt at finde din organisationsbruger. Prøv venligst igen på et senere tidspunkt, eller henvend dig til din kontaktperson eller din overordnede.",
      "button": "Forsøg igen",
      "headline": "Der opstod en fejl under dit log-in."
    },
    "contact": {
      "success": "Din besked er blevet sendt.",
      "yourMessage": "Din besked",
      "trainingSelection": "Træningsvalg",
      "info": "Angiv din forespørgsel i beskedfeltet. Hvis din henvendelse drejer sig om en bestemt træning, bedes du angive hvilken: Så kan din besked sendes videre til det rigtige sted.",
      "chooseReason": "Vælg venligst",
      "otherReasonSubject": "Anden forespørgsel",
      "otherReason": "Generelle kontaktforespørgsler"
    },
    "course": {
      "warning": {
        "body": {
          "1": "Du har startet den valgte træning på et andet sprog end sidste gang.",
          "2": "Hvis du vælger \"Start træning\", nulstilles træningsforløbet."
        },
        "buttons": {
          "start": "Start træning",
          "startPrevious": "Vælg seneste sprog"
        },
        "headline": "Obs.!"
      },
      "noSolved": "Du har endnu ikke fuldført nogen træninger. Gå i gang med din første læringsenhed under punktet \"Mine træninger\".",
      "notification": {
        "success1": "Du har gennemført træningen ",
        "success2": " bestået!"
      },
      "type": {
        "0": "Onlinetræning",
        "1": "Onlinetræning",
        "2": "Onlinetræning",
        "3": "Onlinetræning",
        "4": "Onlinetræning",
        "5": "Onlinetræning",
        "6": "Klasserumstræning",
        "7": "Webinar",
        "-2": "Projekt",
        "undefined": "-"
      },
      "meeting": {
        "multipleMeetings": "div. Terminer",
        "noMeeting": "ingen termin"
      },
      "status": {
        "upcoming": "Forestående",
        "sent": "Sendt",
        "running": "Løbende",
        "passed": "Bestået",
        "participated": "Deltaget",
        "open": "Uafsluttet",
        "notyet": "Endnu ikke tilgængelig",
        "notParticipated": "Ikke deltaget",
        "started": "Behandles",
        "failed": "Ikke bestået",
        "expired": "Udløbet",
        "completed": "Fuldført",
        "booked": "Booket"
      },
      "project": {
        "courses": "Træninger"
      },
      "prefix": {
        "type": "Træningstype",
        "state": "Status",
        "category": "Kategori"
      },
      "statusLabel": {
        "training": "Træning",
        "survey": "Evaluering",
        "exam": "Prøve",
        "efficacyControl": "Effektivitetskontrol",
        "content": "Indhold",
        "attendance": "Tilstedeværelse"
      },
      "noAssigned": "Du har ikke fået tildelt nogen træninger.",
      "noAssignedBookable": "Du har ikke fået tildelt nogen reserverbare træninger i denne kategori.",
      "loadingFailed": "På grund af en ukendt fejl kunne de ønskede dataposter ikke hentes.",
      "start": {
        "survey": "Start evaluering",
        "exam": "Start prøve",
        "efficacyControl": "Start effektivitetskontrol"
      },
      "noResult": "Der blev ikke fundet nogen træninger, der opfylder kriterierne.",
      "noResultInCategory": "Der er endnu ingen træninger i denne kategori."
    },
    "registration": {
      "completeMessage": "Du er nu blevet tilmeldt Akademiet.",
      "actionCodeLimitExceeded": "Det højst mulige antal registreringer i forbindelse med den indtastede kampagnekode er nået.",
      "emailError": "Den e-mailadresse, som du har angivet, er allerede i brug.",
      "send": "Send",
      "required": "Skal udfyldes*",
      "emailErrorNotAllowed": "Med denne e-mailadresse er registreringen ikke mulig.",
      "title": "Din Akademi-tilmelding",
      "registerDescription": "Udfyld nedenstående formular. Du får tilsendt dine adgangsoplysninger via e-mail inden for få minutter.",
      "actionCodeError": "Den indtastede registreringskode er ugyldig",
      "description": "Hvis du endnu ingen log-in-data har, kan du registrere dig ved brug af kampagnekoden, som din virksomhed har modtaget. Du modtager efterfølgende en e-mail med det samme."
    },
    "learningProfile": {
      "gamification": {
        "badge": {
          "achieved": "Du har modtaget et nyt diplom.",
          "congratulations": "Stort tillykke!",
          "title": "Diplomer"
        }
      },
      "tooltip01": "Du har fuldført",
      "withoutCategory": "Uden kategori",
      "points": "Samlet pointtal",
      "restoreFilter": "Nulstil filter",
      "tooltip02": "af",
      "tooltip03": "læringsenheder.",
      "unit": "Læringsenheder",
      "title": "Læringsprofil",
      "history": "Læringshistorik",
      "downloadAll": "Download alle",
      "certificate": "Certifikat"
    },
    "dataProtection": {
      "acceptText": "Ja, jeg accepterer brugerbetingelserne/privatlivspolitikken",
      "accept": "Indvilg"
    },
    "notFound": {
      "headline": "Vi beklager!",
      "text2": "Brug tilbage-tasten i din browser, eller gå ind på startsiden.",
      "text1": "Den ønskede side blev ikke fundet",
      "button": "Tilbage til startsiden"
    },
    "bossBooking": {
      "user": "Bruger",
      "training": "Træning",
      "time": "Klokkeslæt",
      "result": {
        "decline": "Bookingforespørgslen blev afvist.",
        "accept": "Bookingforespørgslen blev godkendt.",
        "error": "Bookingen findes ikke eller er allerede afsluttet.",
        "conflict": "Bookingforespørgslen kunne ikke godkendes, fordi terminen allerede er booket op."
      },
      "project": "Projekt",
      "reason": "Her kan du vælge at angive en begrundelse ...",
      "location": "Location",
      "home": "Tilbage til startsiden",
      "subTitle": {
        "decline": "Ønsker du at afvise bookingforespørgslen?",
        "accept": "Ønsker du at acceptere bookingforespørgslen?"
      },
      "decline": "Afvis",
      "title": "Bookingforespørgsel",
      "meeting": "Termin",
      "accept": "Godkend"
    },
    "securityQuestion": {
      "title": "Sikkerhedstjekspørgsmål",
      "questionSelection": "Vælg et spørgsmål",
      "description": "Svar på sikkerhedstjekspørgsmålet, og vælg et nyt password til din brugerkonto.",
      "yourAnswer": "Angiv dit svar",
      "chooseQuestion": "Vælg venligst"
    },
    "catalog": {
      "bookingDialog": {
        "notice": "Angiv begrundelsen for din træningsforespørgsel her"
      },
      "back": "Tilbage"
    },
    "courseCalendar": {
      "expiredMeetings": "Overståede terminer",
      "soonExpired": "Udløber snart",
      "expired": "Udløbet"
    },
    "faq": {
      "title": "Ofte stillede spørgsmål"
    },
    "booking": {
      "cancel": {
        "title": "Annullér booking",
        "confirm": "Er du sikker på, at du vil annullere din booking?"
      }
    }
  },
  "notifications": {
    "success": {
      "securityQuestion": {
        "questionSave": "Dit sikkerhedstjekspørgsmål er blevet gemt."
      },
      "profile": {
        "updateSuccess": "Din profil er blevet opdateret.",
        "updatePasswordSuccess": "Dit password er blevet opdateret."
      },
      "request": {
        "webinar": "Din forespørgsel om webinaret er blevet sendt.",
        "course": "Din forespørgsel om træningen er blevet sendt.",
        "project": "Din forespørgsel om projektet er blevet sendt.",
        "meeting": "Din forespørgsel om klasserumstræningen er blevet sendt."
      },
      "book": {
        "webinar": "Din booking af webinaret er gennemført.",
        "course": "Din booking af træningen er gennemført.",
        "project": "Din booking af projektet er gennemført.",
        "meeting": "Din booking af klasserumstræningen er gennemført."
      }
    },
    "errors": {
      "imageLoad": "Det ønskede billede kunne ikke hentes",
      "missingInput": "Udfyld felterne"
    },
    "error": "Noget gik galt."
  },
  "webservice": {
    "success": {
      "request": "Din forespørgsel er blevet sendt.",
      "cancel": "Din annullering er blevet gennemført. ",
      "booking": "Din booking er gennemført.",
      "waitlist": {
        "add": "Du er nu på ventelisten"
      },
      "reservelist": {
        "add": "Du er nu på interesselisten"
      }
    },
    "courseCalendar": {
      "noDate": "Uden frist"
    },
    "error": {
      "duplicate": "Bookingen findes allerede.",
      "notimplementet": "Funktionen er p.t. ikke tilgængelig.",
      "noaccess": "Adgang ikke tilladt."
    }
  },
  "password": {
    "requirements": {
      "maxPasswordAttempts": "Du har stadig #0 forsøg tilbage til at indtaste det rigtige password.",
      "mandantName": "Begrebet \"#0\" må ikke anvendes.",
      "birthday": "Du må ikke bruge din egen fødselsdato som password.",
      "title": "Retningslinjer for valg af password",
      "userNameEqual": "Passwordet må ikke indeholde dit brugernavn.",
      "userName": "Passwordet må ikke indeholde dit brugernavn.",
      "oldPassword": "Det gamle password må ikke benyttes.",
      "names": "Passwordet må ikke være dit for- og efternavn.",
      "sameLetters": "Der må ikke anvendes mere end #0 tegn.",
      "bigLetters": "Der skal mindst anvendes #0 store bogstaver.",
      "minSymbolsPolicy": "Der skal mindst anvendes #0 specialtegn.",
      "smallLetters": "Der skal mindst anvendes #0 små bogstaver.",
      "digits": "Der skal mindst anvendes #0 tal.",
      "minimalLength": "Længden skal være på mindst #0 tegn.",
      "minPasswordLength": "Passwordet skal bestå af mindst #0 tegn."
    }
  },
  "general": {
    "offline": {
      "headline": "Ingen internetforbindelse.",
      "text": "For at denne anvendelse kan fungere korrekt kræver det en aktiv internetforbindelse."
    },
    "year": "År",
    "showMore": "Vis mere",
    "showLess": "Vis mindre",
    "months": {
      "10": "Oktober",
      "11": "November",
      "12": "December",
      "09": "September",
      "05": "Maj",
      "03": "Marts",
      "06": "Juni",
      "07": "Juli",
      "01": "Januar",
      "02": "Februar",
      "08": "August",
      "04": "April"
    },
    "search": "Søg",
    "pagination": {
      "prev": "Tilbage",
      "separator": "af",
      "next": "Videre",
      "pageSize": "Afbildning pr. side"
    },
    "searchError": "Angiv mindst tre tegn.",
    "noResult": "Der blev ikke fundet noget resultat.",
    "month": "Måned",
    "category": "Kategori",
    "calendar": "Kalender"
  },
  "button": {
    "waitlist": "Venteliste",
    "start_webinar": "Start webinar",
    "start_course": "START",
    "login-sso": "SINGLE-SIGN-ON LOGIN",
    "login": "LOG-IN",
    "send": "SEND",
    "save": "Gem",
    "reset": "Nulstil",
    "open_webinar": "Åbn webinar",
    "request": "Forespørgsel",
    "confirm": "Bekræft",
    "close": "Luk",
    "changePassword": "Ændr password",
    "storno": "Annuller",
    "cancel": "Afbryd",
    "booking": "Book",
    "canceled": "Termin annulleret"
  },
  "bookingState": {
    "0": "FORESPURGT",
    "1": "BOOKET",
    "2": "VENTELISTE",
    "3": "ANNULLERET",
    "4": "ANNULLERET",
    "5": "AFVIST",
    "6": "INTERESSE TILKENDEGIVET",
    "onlineTraining": {
      "0": "FORESPURGT",
      "1": "BOOKET",
      "2": "AFVIST"
    }
  },
  "foodPreference": {
    "vegetarian": "Vegetarisk",
    "vegan": "Vegan",
    "other": "Øvrige",
    "nutAllergy": "Nøddeallergi",
    "none": "Ingen",
    "lactoseIntolerance": "Laktose intolerance",
    "kosher": "Kosher",
    "halal": "Halal",
    "glutenFree": "Glutenfri",
    "diabetic": "Diabetes"
  },
  "placeholder": {
    "username": "Brugernavn",
    "actionCode": "Kampagnekode",
    "password": "Password"
  },
  "sidebar": {
    "courseType": "TRÆNINGSTYPE",
    "topics": "EMNEOMRÅDER",
    "status": "STATUS",
    "categories": "KATEGORIER",
    "all": "Alle"
  },
  "trainingmanager": {
    "requestform": {
      "tab01headline02": "Videreuddannelsens tema:",
      "tab01label07": "Titel*:",
      "tab01": "Indgiv anmodning",
      "tab01label18": "Send anmodning",
      "tab01label08": "Kort beskrivelse:",
      "tab01label16": "Vælg fil",
      "tab01label01": "Tiltale:",
      "requester": "Ansøger:",
      "tab01label14": "Udbyder:",
      "tab01label13": "Pris:",
      "olbodytext": "Vælg efterfølgende navnene på de øvrige træningsdeltagere.",
      "tab01label09": "Øvrige:",
      "tab01label09O": "Øvrige",
      "olerror": "Der blev ikke fundet nogen deltagere. Ændr dine søgekriterier.",
      "tab01label17": "Der er ikke valgt nogen fil ...",
      "tab01headline04": "Navn på deltagerne:",
      "tab01headline01": "Navn på deltageren:",
      "olheadline1": "Navn på deltageren",
      "tab01label05": "Fru",
      "tab01label04": "Hr.",
      "tab01label03": "Navn:",
      "tab02": "Anmodning for gruppe/tredjepart",
      "tab01label02": "Fornavn:",
      "date": "Dato:",
      "tab01label11": "Termin (fra/til):*",
      "tab01label12": "Kursusnummer:",
      "tab01headline03": "Kursusdetaljer:",
      "tab01label10": "Budgetenhed:",
      "tab01label06": "Kategori:",
      "tab01label20": "Afbryd",
      "tab01label15": "Bilag:",
      "tab01label19": "Tilføj yderligere person",
      "olheadline": "Tilføj deltager"
    },
    "manage": {
      "tableHeadline04": "Titel",
      "headlineInfo": "Medarbejderforespørgsler",
      "newRequest": "Send egen anmodning",
      "modal": {
        "refuse": {
          "button": "Send",
          "refuse": "Er ikke frigivet",
          "approval": "Er godkendt",
          "placeholder": "Skriv en tekst ....",
          "headline": "Besked - afvis anmodning",
          "checkbox": "Afvis anmodning uden besked"
        }
      },
      "tableMark": "Markér alle",
      "tableHeadline02": "Ansøger",
      "tableHeadline06": "Pris",
      "headline": "Administrer anmodninger",
      "tableHeadline01": "Indgang",
      "buttondecline": "Frigiv ikke",
      "tableHeadline05": "Termin",
      "tableHeadline03": "Kursus-nr.",
      "bodytextInfo": "Efterfølgende finder du alle uafsluttede videreuddannelsesanmodninger, som du opfordres til at færdiggøre.",
      "buttonapprove": "Godkend"
    },
    "headlinerequest": "Indgiv anmodning",
    "bottonrequest": "Indgiv anmodning",
    "headline": "Videreuddannelseskrav",
    "buttonmanage": "Administrer anmodninger"
  },
  "main_navigation": {
    "coursesDetail": "Træning detaljer",
    "terms_and_conditions": "Brugerbetingelser/privatlivspolitik",
    "trainingmanager": "Anmodninger",
    "trainingmanagerrequest": "Forespørgsel",
    "registration": "Registrering",
    "profile": "Profil",
    "courses": "Mine træninger",
    "trainingmanagermanage": "Manage",
    "logout": "Log af",
    "login": "Log-in",
    "learningProfile": "Læringsprofil",
    "about": "Impressum",
    "pwLost": "Glemt dine log-in-data?",
    "faq": "FAQ",
    "trainingmanagermanagedetail": "Nærmere oplysninger",
    "contact": "Kontakt",
    "catalog": "Katalog"
  },
  "maintenance": {
    "title": "Vedligeholdelsesarbejder",
    "text": "På grund af vedligeholdelsesarbejder er Akademiet p.t. ikke tilgængeligt. Forsøg venligst igen på et senere tidspunkt."
  }
}
