import CustomizingLoader from '@/utils/CustomizingLoader';
import { lazyLoading } from '@/utils/helpers';

export const BookingRequest = {
  path: '/booking-request',
  component: () => CustomizingLoader('views/layout/pages/LayoutNotFound'),
  showInMenu: false,
  children: [{
    path: '',
    name: 'booking-request',
    component: lazyLoading('booking')
  }]
};
