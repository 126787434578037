let host = window.location.protocol + '//' + window.location.host;

export default {
  defaultLanguage: 'de',
  host,
  windowTitle: 'Academy',
  customizing: process.env.NODE_ENV === 'production' ? window.CUSTOMIZING: 'x-cell',
  logLevel: process.env.NODE_ENV === 'production' ? window.LOG_LEVEL : 7,
  localStorage: {
    key: 'xcell-academy-storage',
    lifetime: 7 * 24 * 60 * 60 * 1e3 // one week
  }
};
