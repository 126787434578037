export const Module = {
  OPEN_TRAININGS: 1,
  EXCEL_USER_SYNCH: 3,
  MULTIPLE_TRAININGCENTERS: 5,
  LMS_ADMINISTRATION: 7,
  RESULT_ANONYMIZATION: 8,
  ALLOW_USERPROFILE_MODIFICATION: 10,
  ALLOW_IPHONE_MP4: 12,
  SAML_ADFS_INTERFACE: 15,
  ACADEMY_MANAGER: 16,
  CSV_IMPORT: 17,
  CUMULATIVE_RESULTSTATISTIK: 20,
  PDF_VERSION2: 21,
  REG_DUPLICATE_CHECK: 22,
  WEBINARS: 24,
  SCORM_ADMINISTRATION: 26,
  AD_LDAPSYNC: 27,
  MYACADEMY_VIEWONLY: 28,
  VIDEOCONVERT: 32,
  POWERPOINT_STRUCTURE_CONVERSION: 36,
  LEARN_CHECK_FUNCTION: 37,
  EXTERNAL_QUALI: 40,
  SECURITY_QUESTION: 41,
  OAUTH_INTERFACE: 42,
  AUDIO_RECORDING: 43,
  ANTIBRUTEFORCE: 45,
  REQUIRED_SURVEY: 48,
  FAQ_AREA: 54,
  NEWS_AREA: 55,
  EFFICACY_CONTROL: 56,
  GAMIFICATION: 57,
  WEBHOOKS: 58,
  RECURRING_CLASSROOM: 59,
  PARENT_CATEGORY: 60,
  COURSE_TAGS: 61,
  PUBLIC_CATALOG: 62,
  CATEGORY_FILTER: 63,
  MULTI_FACTOR_AUTHENTICATION_TOTP: 64,
  COURSE_DOCUMENT: 65,
  CATEGORY_CALENDAR: 66
};
