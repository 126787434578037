import Vue from 'vue';
import Router from 'vue-router';
import { generateRoutes } from './routes';
import store from '../store/RootStore';

Vue.use(Router);

function generateRoutesFromMenu(menu = [], routes = []) {
  for (let i = 0, l = menu.length; i < l; i++) {
    let item = menu[i];
    if (item.path) {
      routes.push(item);
    }
    if (!item.component) {
      generateRoutesFromMenu(item.children, routes);
    }
  }
  return routes;
}

export const getRouter = (modules) => {
  const routesItems = generateRoutes(modules);

  const router = new Router({
    // mode: 'history',
    linkActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: generateRoutesFromMenu(routesItems)
  });

  function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
      context.next(...parameters);
      const nextMiddleware = nextFactory(context, middleware, index + 1);
      subsequentMiddleware({ ...context, next: nextMiddleware });
    };
  }

  router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];

      const context = {
        from,
        next,
        router,
        to
      };
      const nextMiddleware = nextFactory(context, middleware, 1);
      if (from.name !== null) {
        store.dispatch('userStore/setLastVisitedPage', from.name);
        store.dispatch('userStore/setLastVisitedPagePath', from.path);
      }
      return middleware[0]({ ...context, next: nextMiddleware});
    }
    return next();
  });

  return router;
};
