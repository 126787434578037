import CustomizingLoader from '@/utils/CustomizingLoader';
import Auth from '@/middleware/auth';
import { lazyLoading } from '@/utils/helpers';

export const CourseDetail = {
  path: '/course/:id/detail',
  component: () => CustomizingLoader('views/layout/pages/Layout'),
  showInMenu: false,
  meta: {
    label: 'main_navigation.coursesDetail',
    middleware: Auth
  },
  children: [{
    path: '',
    name: 'courseDetail',
    component: lazyLoading('courses', 'Detail'),
    meta: {
      label: 'main_navigation.coursesDetail',
      middleware: Auth
    }
  }]
};
