// Tschechisch

module.exports = {
  "main_navigation": {
    "login": "Přihlášení",
    "registration": "Registrace",
    "courses": "Moje cvičení",
    "coursesDetail": "Detaily cvičení",
    "catalog": "Katalog",
    "learningProfile": "Učební profil",
    "contact": "Kontakt",
    "profile": "Profil",
    "pwLost": "Zapomněli jste přihlašovací údaje?",
    "about": "Tiráž",
    "terms_and_conditions": "Podmínky použití/ochrana dat",
    "logout": "Odhlásit",
    "faq": "Nejčastěji kladené otázky",
    "trainingmanager": "Žádosti",
    "trainingmanagerrequest": "Požadavek",
    "trainingmanagermanage": "Spravovat",
    "trainingmanagermanagedetail": "Detail"
  },
  "general": {
    'offline': {
      'headline': 'Jste offline!',
      'text': 'Tato aplikace potřebuje ke správné funkci aktivní připojení k internetu.',
    },
    "year": "Rok",
    "month": "Měsíc",
    "noResult": "Nebyl nalezen žádný výsledek.",
    "months": {
      "10": "Říjen",
      "11": "Listopad",
      "12": "Prosinec",
      "01": "Leden",
      "02": "Únor",
      "03": "Březen",
      "04": "Duben",
      "05": "Květen",
      "06": "Červen",
      "07": "Červenec",
      "08": "Srpen",
      "09": "Září"
    },
    "category": "Kategorie",
    "calendar": "Kalendář",
    "search": "Hledání",
    "searchError": "Zadejte prosím alespoň tři znaky.",
    "showLess": "Zobrazit méně",
    "showMore": "Zobrazit více",
    'pagination': {
      'next': 'Další',
      'prev': 'Předchozí',
      'separator': 'z',
      'pageSize': 'Záznamů na stránku'
    }
  },
  "maintenance": {
    "title": "Údržbářské práce",
    "text": "Akademie v současné době není k dispozici z důvodu údržby. Zkuste to prosím později."
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Je nám to líto!",
      "text1": "Požadovaná stránka nebyla nalezena",
      "text2": "Použijte tlačítko Zpět ve svém prohlížeči nebo přejděte na domovskou stránku. ",
      "button": "Zpět na domovskou stránku"
    },
    "courseCalendar": {
      "expired": "Vypršelo",
      "soonExpired": "Brzy vyprší",
      "expiredMeetings": "Minulá data"
    },
    "profile": {
      "userdata": "Uživatelská data",
      "password": "Heslo",
      "genderLab": "Oslovení",
      "gender": {
        "men": "Pan",
        "woman": "Paní",
        "diverse": "Jiné",
        "unknown": "Bez udání"
      },
      "firstname": "Křestní jméno",
      "lastname": "Příjmení",
      "email": "E-mail",
      "strasse": "Ulice / č.",
      "city": "PSČ / obec",
      "phone": "Telefon",
      "fax": "Fax",
      "position": "Pozice",
      "department": "Oddělení",
      "required": "Povinné pole",
      "oldPassword": "Staré heslo",
      "newPassword": "Nové heslo",
      "newPasswordRep": "Opakování",
      "company": "Podnik",
      "language": "Jazyk"
    },
    "catalog": {
      "back": "Zpět",
      "bookingDialog": {
        "notice": "Sem zadejte důvod žádosti o cvičení"
      }
    },
    "contact": {
      "info": "Zadejte prosím svůj požadavek do pole pro zprávu. Pokud odkazujete na konkrétní cvičení, uveďte jej: Vaše zpráva může být přeposlána přímo na správné místo.",
      "trainingSelection": "Výběr cvičení",
      "yourMessage": "Vaše zpráva",
      "success": "Tvoje zpráva byla úspěšně odeslána!",
      "otherReason": "Obecná žádost o kontakt",
      "otherReasonSubject": "Jiná žádost",
      "chooseReason": "Prosím zvolte"
    },
    "learningProfile": {
      "title": "Učební profil",
      "unit": "Učební jednotky",
      "points": "Konečný počet bodů",
      "history": "Učební historie",
      "restoreFilter": "Smazat filtr",
      "certificate": "Certifikát",
      'downloadAll': 'Stáhnout vše',
      "withoutCategory": "Bez kategorie",
      "tooltip01": "Absolvovali jste",
      "tooltip02": "z",
      "tooltip03": "učebních jednotek.",
      'gamification': {
        'badge':{
          'title': 'Ocenění',
          'congratulations': 'Gratulujeme!',
          'achieved': 'Obdrželi jste nové ocenění!'
        }
      }
    },
    "courseDetail": {
      "title": "Podrobnosti o cvičení",
      "back": "Zpět na seznam",
      "progress": "Pokrok",
      "expiry": "platné do",
      "classroomNo": "Číslo školení",
      "contactPerson": "Kontaktní osoba",
      "targetGroup": "Cílová skupina",
      "bonusPoints": "Bonusové body",
      "classroomContent": "Obsah školení",
      "webinarLight": "Informace o webináři",
      "specials": "Zvláštnosti",
      "stornoConditions": "Podmínky stornování",
      "meeting": "termín",
      "meetings": "termíny",
      'freeDay': 'Free day',
      'freeDayInfo': 'Volné dny nejsou uvedeny výše\n',
      "speaker": "Lektor / referent",
      "location": "Místo školení",
      "requested": "VYŽÁDÁNO",
      "waitlist": "ČEKACÍ LISTINA",
      "fullyBooked": "OBSAZENO",
      "edit": "Upravit",
      "webinarSpeaker": "Referent",
      "docsAndLinks": "Dokumenty a odkazy",
      "links": "Odkazy",
      "webinar": {
        "host": "Otevřít webinář",
        "state": {
          "ended": "Webinář je ukončen",
          "beforeStart": "Webinář začíná dne"
        }
      },
      "sidebar": {
        "price": "Cena na osobu",
        "duration": "Doba trvání výuky",
        "to": "do",
        "numberOfParticipants": "Počet účastníků",
        "status": "Status registrace",
        'totalStatus': 'Celkový stav',
        'contentStatus': 'Stav školení'
      },
      "bookingInformation": {
        "title": "Informace o registraci",
        "note": "Poznámka",
        "accounts": "Nákladové středisko",
        "invoiceRecipient": "Příjemce faktury",
        "invoiceAddress": "Fakturační adresa",
        "food": "Stravovací preference",
        "arrival": "Příjezd",
        "arrivalType": {
          "none": "Bez udání",
          "car": "Auto",
          "train": "Vlak",
          "plane": "Letadlo"
        },
        "arrivalDate": "Datum příjezdu",
        "departure": "Odjezdu",
        "departureDate": "Datum odjezdu",
        "flightNumber": "Číslo letu",
        "trainNumber": "Číslo vlaku",
        "railwayStation": "Nádraží",
        'placeholder': {
          'departure_airport': 'Letiště odletu',
          'destination_airport': 'Cílové letiště',
          'departure_trainstation': 'Odjezdová stanice',
          'destination_trainstation': 'Cílová stanice',
          'departure_car': 'Místo odjezdu',
          'destination_car': 'Místo určení',
          'from': 'z',
          'to': 'na'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          'Leden',
          'Únor',
          'Březen',
          'Duben',
          'Květen',
          'Červen',
          'Červenec',
          'Srpen',
          'Září',
          'Říjen',
          'Listopad',
          'Prosinec'
        ]
      },
      "reserve": {
        "title": "Tento/tyto termín/y nemůžete zařídit?",
        "text": "Uveďte, oč se zajímáte! Poté budete informováni o možných nových termínech.",
        "button": "Předběžně zaznamenat",
        "prebooked": {
          "text": "Předběžně jste zaznamenali svůj zájem o toto cvičení."
        }
      },
      'downloads': {
        'modalHeadline': 'Ke stažení na',
        'sidebarLink': 'Dokument(y)',
      }
    },
    "course": {
      "type": {
        "0": "Online cvičení",
        "1": "Online cvičení",
        "2": "Online cvičení",
        "3": "Online cvičení",
        "4": "Online cvičení",
        "5": "Online cvičení",
        "6": "Prezenční cvičení",
        "7": "Webinář",
        "-2": "Projekt",
        "undefined": "-"
      },
      "status": {
        "open": "Otevřené",
        'sent': 'Sent',
        "passed": "Úspěšně zvládnuté",
        'sent': 'Odesláno',
        "failed": "Nezvládnuté",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Vypršelo",
        "booked": "Zaregistrováno",
        "started": "Zpracovává se",
        "upcoming": "- V jakým procesu?",
        "running": "Probíhající",
        "completed": "Dokončeno"
      },
      "notification": {
        "success1": "Školení jste úspěšně",
        "success2": "absolvovali!"
      },
      'warning': {
        'headline': 'Pozor!',
        'body': {
          '1': 'Pro toto školení jste zvolili jiný jazyk než minule.',
          '2': 'Pokud pokračujete a kliknete na "Zahájit školení", postup se resetuje.',
        },
        'buttons': {
          'start': 'Zahájit školení',
          'startPrevious': 'Výběr předchozího jazyka',
        }
      },
      "statusLabel": {
        "training": "Cvičení",
        'content': 'Obsah',
        'attendance': 'Attendance',
        "exam": "Zkouška",
        "survey": "Dotazování"
      },
      "start": {
        "survey": "Spustit dotazování",
        "exam": "Zahájit zkoušku"
      },
      "prefix": {
        "category": "Kategorie",
        "state": "Stav",
        "type": "Typ cvičení"
      },
      "noAssigned": "Nejsou vám přiřazena žádná cvičení.",
      "noSolved": "Dosud jste neabsolvovali žádná cvičení. Spusťte svou první učební jednotku v oblasti „Má cvičení“!",
      "noAssignedBookable": "Nejsou vám přiřazena žádná rezervovatelná cvičení.",
      "noResult": "Nebyla nalezena žádná cvičení, která by odpovídala kritériím.",
      "loadingFailed": "Požadované datové záznamy nelze načíst kvůli neznámé chybě!",
      "noResultInCategory": "K této kategorii zatím nebyla přiřazena žádná cvičení.",
      "meeting": {
        "noMeeting": "žádný termín",
        "multipleMeetings": "růz. termíny"
      },
      "project": {
        "courses": "cvičení"
      }
    },
    "login": {
      "error": "Nesprávné uživatelské jméno a/nebo heslo.",
      "empty": "Prosím vyplňte všechna pole.",
      "back": "Zpět k přihlášení",
      "description": "Zadejte své uživatelské jméno nebo vaši e-mailovou adresu, abychom vám mohli znovu zaslat přihlašovací údaje.",
      "password-reset": {
        "description": "Vyberte prosím nové heslo pro svůj uživatelský účet.",
        "success": "Vaše heslo bylo úspěšně změněno.",
        "error": "Vámi zadaná hesla se neshodují."
      },
      "placeholder": "Uživatelské jméno / e-mailová adresa",
      "button": "Vyžádejte si přístupová data",
      "success": "Žádost byla úspěšně odeslána.",
      "errorForgot": "Nebyl nalezen žádný uživatel, který by splňoval zadaná kritéria.",
      "errorForgotSecurityQuestion": "Odpověď bohužel není správná!"
    },
    "registration": {
      "description": "Pokud nemáte žádné přihlašovací údaje, můžete se jednoduše zaregistrovat pomocí akčního kódu vaší společnosti. Poté vám bude obratem zaslán e-mail.",
      "actionCodeError": "Zadaný registrační kód je neplatný",
      "actionCodeLimitExceeded": "Byl vyčerpán maximální počet možných registrací pro zadaný akční kód.",
      "registerDescription": "Vyplňte prosím níže uvedený formulář. Vaše přihlašovací údaje vám budou zaslány e-mailem za několik minut.",
      "title": "Vaše registrace do akademie",
      "required": "Povinné pole*",
      "emailError": "E-mailová adresa, kterou jste zadali, je již obsazena.",
      "send": "Odeslat",
      "completeMessage": "Úspěšně jste se zaregistrovali do akademie!"
    },
    "dataProtection": {
      "accept": "Souhlas",
      "acceptText": "Ano, souhlasím s podmínkami používání/ochranou údajů"
    },
    "securityQuestion": {
      "title": "Bezpečnostní dotaz",
      "chooseQuestion": "Prosím zvolte",
      "questionSelection": "Vyberte prosím otázku",
      "yourAnswer": "Zadejte svou odpověď",
      "description": "Odpovězte na bezpečnostní otázku a vyberte nové heslo pro svůj uživatelský účet."
    },
    "faq": {
      "title": "Často kladené otázky"
    }
  },
  "sidebar": {
    "all": "Všechny",
    "categories": "KATEGORIE",
    "status": "STATUS",
    "courseType": "TYP CVIČENÍ",
    "topics": "TEMATICKÉ OBLASTI"
  },
  "placeholder": {
    "username": "Uživatelské jméno",
    "password": "Heslo",
    "actionCode": "Akční kód"
  },
  "button": {
    "login": "PŘIHLÁSIT",
    "login-sso": "AKTIVNÍ PŘIHLÁŠENÍ DO ADRESÁŘE",
    "send": "POSLAT",
    "start_course": "START",
    "changePassword": "Změnit heslo",
    "reset": "Obnovit",
    "save": "Uložit",
    "request": "Požadavek",
    "storno": "Stornovat",
    "close": "Zavřít",
    "booking": "Zaregistrovat",
    "waitlist": "Čekací listina",
    "start_webinar": "Spustit webinář",
    "open_webinar": "Otevřít webinář",
    'cancel': 'Zrušit',
    'confirm': 'Potvrďte'
  },
  "bookingState": {
    "0": "VYŽÁDÁNO",
    "1": "ZAREGISTROVÁNO",
    "2": "ČEKACÍ LISTINA",
    "3": "STORNOVÁNO",
    "4": "STORNOVÁNO",
    "5": "ZAMÍTNUTO",
    "6": "ZAZNAMENÁNO",
    "onlineTraining": {
      "0": "VYŽÁDÁNO",
      "1": "ZAREGISTROVÁNO",
      "2": "ZAMÍTNUTO"
    }
  },
  "foodPreference": {
    "none": "Žádné",
    "lactoseIntolerance": "Nesnášenlivost laktózy",
    "vegetarian": "Vegetarián",
    "vegan": "Vegan",
    "nutAllergy": "Alergie na ořechy",
    "diabetic": "Cukrovka",
    "glutenFree": "Bezlepkový",
    "kosher": "Košer",
    "halal": "Halal",
    "other": "Ostatní"
  },
  "notifications": {
    "error": "Něco se nepovedlo!",
    "errors": {
      "missingInput": "Vyplňte prosím tato pole",
      "imageLoad": "Požadovaný obrázek nelze načíst"
    },
    "success": {
      "book": {
        "meeting": "Úspěšně jste si rezervovali prezenční cvičení.",
        "webinar": "Úspěšně jste si rezervovali webinář.",
        "course": "Úspěšně jste si rezervovali cvičení.",
        "project": "Úspěšně jste si rezervovali projekt."
      },
      "request": {
        "meeting": "Úspěšně jste si zažádali o prezenční cvičení.",
        "webinar": "Úspěšně jste si zažádali o webinář.",
        "course": "Úspěšně jste si zažádali o cvičení.",
        "project": "Úspěšně jste si zažádali o projekt."
      },
      "profile": {
        "updateSuccess": "Úspěšně jste aktualizovali svůj profil!",
        "updatePasswordSuccess": "Úspěšně jste aktualizovali své heslo!"
      },
      "securityQuestion": {
        "questionSave": "Váš bezpečnostní dotaz byl uložen!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Rezervace již existuje!",
      "noaccess": "Přístup není povolen.",
      "notimplementet": "Funkce není v tuto chvíli k dispozici."
    },
    "success": {
      "booking": "Úspěšně jste rezervovali.",
      "request": "Úspěšně jste zažádali.",
      "cancel": "Úspěšně jste stornovali. ",
      "waitlist": {
        "add": "Byli jste zařazeni na čekací listinu"
      },
      "reservelist": {
        "add": "Byli jste zapsáni do seznamu předběžných registrací"
      }
    },
    "courseCalendar": {
      "noDate": "Bez lhůty"
    }
  },
  "trainingmanager": {
    "headline": "Požadavek na další vzdělávání",
    "headlinerequest": "žádost",
    "bottonrequest": "Podat žádost",
    "buttonmanage": "Správa žádostí",
    "requestform": {
      "requester": "Žadatel:",
      "date": "Datum:",
      "tab01": "Podat požadavek",
      "tab02": "Požadavek na skupinu/třetí strany",
      "tab01headline01": "Jméno účastníka:",
      "tab01headline02": "Téma dalšího vzdělávání:",
      "tab01headline04": "Jména účastníků:",
      "tab01headline03": "Detaily kurzu:",
      "tab01label01": "Oslovení:",
      "tab01label02": "Křestní jméno:",
      "tab01label03": "Jméno:",
      "tab01label04": "Pan",
      "tab01label05": "Paní",
      "tab01label06": "Kategorie:",
      "tab01label07": "Titul*:",
      "tab01label08": "Stručný popis:",
      "tab01label09": "Ostatní:",
      "tab01label09O": "Ostatní",
      "tab01label10": "Nákladové středisko (pokud se liší):",
      "tab01label11": "Datum (od/do):*",
      "tab01label12": "Číslo kurzu:",
      "tab01label13": "Cena:",
      "tab01label14": "Poskytovatel:",
      "tab01label15": "Příloha:",
      "tab01label16": "Vybrat soubor",
      "tab01label17": "Není vybrán žádný soubor...",
      "tab01label18": "Odeslat žádost",
      "tab01label19": "Přidat další osobu",
      "tab01label20": "Přerušit",
      "olheadline": "Přidat účastníky",
      "olheadline1": "Jméno účastníka",
      "olbodytext": "Níže vyberte jména ostatních účastníků cvičení.",
      "olerror": "Nenalezeni žádní účastníci. Změňte parametry vyhledávání."
    },
    "manage": {
      "headline": "Správa žádostí",
      "headlineInfo": "Požadavky zaměstnanců",
      "bodytextInfo": "Níže najdete všechny otevřené požadavky na další vzdělávání s požadavkem na zpracování.",
      "newRequest": "Zašlete svou vlastní žádost",
      "tableHeadline01": "Vstup",
      "tableHeadline02": "Žadatel",
      "tableHeadline03": "Kurz č.",
      "tableHeadline04": "Titul",
      "tableHeadline05": "Termín",
      "tableHeadline06": "Cena",
      "tableMark": "Označit vše",
      "buttonapprove": "Udělit schválení",
      "buttondecline": "Neudělit souhlas",
      "modal": {
        "refuse": {
          "headline": "Zpráva - odmítnout požadavek",
          "bodytext": "",
          "placeholder": "Zadejte prosím text....",
          "checkbox": "Odmítněte požadavek bez zprávy",
          "button": "Poslat",
          "approval": "Souhlas udělen",
          "refuse": "Schválení neuděleno"
        }
      }
    }
  }
}
