// Spanisch

module.exports = {
  'main_navigation': {
    'login': 'Iniciar sesión',
    'registration': 'Registrarse',
    'courses': 'Mis formaciones',
    'coursesDetail': 'Detalles de la formación',
    'catalog': 'Catálogo',
    'learningProfile': 'Perfil de aprendizaje',
    'contact': 'Contacto',
    'profile': 'Perfil',
    'pwLost': '¿Ha olvidado sus datos de acceso?',
    'about': 'Aviso legal',
    'terms_and_conditions': 'Condiciones de uso/Protección de datos',
    'logout': 'Cerrar sesión',
    'faq': 'FAQ',
    'trainingmanager': 'Solicitudes',
    'trainingmanagerrequest': 'Consulta',
    'trainingmanagermanage': 'Gestión',
    'trainingmanagermanagedetail': 'Detalle'
  },
  'general': {
    'offline': {
      'headline': 'Estás fuera de línea.',
      'text': 'Esta aplicación necesita una conexión a Internet activa para funcionar correctamente.',
    },
    'year': 'Año',
    'month': 'Mes',
    'noResult': 'No se encontró ningún resultado.',
    'months': {
      '10': 'Octubre',
      '11': 'Noviembre',
      '12': 'Diciembre',
      '01': 'Enero',
      '02': 'Febrero',
      '03': 'Marzo',
      '04': 'Abril',
      '05': 'Mayo',
      '06': 'Junio',
      '07': 'Julio',
      '08': 'Agosto',
      '09': 'Septiembre'
    },
    'category': 'Categoría',
    'calendar': 'Calendario',
    'search': 'Buscar',
    "searchError": "Por favor, introduzca al menos tres caracteres.",
    'showLess': 'Mostrar menos',
    'showMore': 'Mostrar más',
    'pagination': {
      'next': 'Siguiente',
      'prev': 'Anteriormente',
      'separator': 'de',
      'pageSize': 'Elementos por página',
    }
  },
  'maintenance': {
    'title': 'Tareas de mantenimiento',
    'text': 'La academia no está disponible en estos momentos porque estamos realizando tareas de mantenimiento. Por favor, vuelva a intentarlo más tarde.'
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  'pages': {
    'notFound': {
      'headline': '¡Lo sentimos!',
      'text1': 'No se ha encontrado la página solicitada.',
      'text2': 'Utilice el botón de su navegador para volver atrás o vaya a la página de inicio.',
      'button': 'Volver a la página de inicio'
    },
    'courseCalendar': {
      'expired': 'Expirado',
      'soonExpired': 'Expira pronto',
      'expiredMeetings': 'Fechas anteriores'
    },
    'profile': {
      'userdata': 'Datos de usuario',
      'password': 'Contraseña',
      'genderLab': 'Tratamiento',
      'gender': {
        'men': 'Señor',
        'woman': 'Señora',
        'diverse': 'Diverso',
        'unknown': 'No especificado'
      },
      'firstname': 'Nombre',
      'lastname': 'Apellido',
      'email': 'Correo electrónico',
      'strasse': 'Calle / Número',
      'city': 'C.P. / Localidad',
      'phone': 'Teléfono',
      'fax': 'Fax',
      'position': 'Posición',
      'department': 'Departamento',
      'required': 'Campo obligatorio',
      'oldPassword': 'Contraseña antigua',
      'newPassword': 'Contraseña nueva',
      'newPasswordRep': 'Repetir contraseña',
      'company': 'Empresa',
      'language': 'Idioma'
    },
    'catalog': {
      'back': 'Volver'
    },
    'contact': {
      'info': 'Por favor, escriba su consulta en el campo del mensaje. Si se refiere a una formación en concreto, por favor, indíquela: De este modo podremos redirigir su consulta a la persona competente.',
      'trainingSelection': 'Seleccionar formación',
      'yourMessage': 'Su mensaje',
      'success': '¡Su mensaje se ha enviado correctamente!',
      'otherReason': 'Solicitudes de contacto generales',
      'otherReasonSubject': 'Pedir otros',
      'chooseReason': 'Por favor, seleccione'
    },
    'learningProfile': {
      'title': 'Perfil de aprendizaje',
      'unit': 'Unidades de aprendizaje',
      'points': 'Puntuación total',
      'history': 'Historial de aprendizaje',
      'restoreFilter': 'Restablecer filtros',
      'certificate': 'Certificado',
      'downloadAll': 'Descargar todo',
      'withoutCategory': 'Sin categoría',
      'tooltip01': 'Ha completado',
      'tooltip02': 'de',
      'tooltip03': 'unidades de aprendizaje.',
      'gamification': {
        'badge':{
          'title': 'Premios',
          'congratulations': 'Enhorabuena!',
          'achieved': 'Has recibido un nuevo premio!'
        }
      }
    },
    'courseDetail': {
      'title': 'Detalles de la formación',
      'back': 'Volver a la lista',
      'progress': 'Progreso',
      'expiry': 'válido hasta',
      'classroomNo': 'Número de formación',
      'contactPerson': 'Persona de contacto',
      'targetGroup': 'Grupo objetivo',
      'bonusPoints': 'Puntos de bonificación',
      'classroomContent': 'Contenidos de la formación',
      'specials': 'Particularidades',
      'stornoConditions': 'Condiciones de cancelación',
      'meeting': 'Cita',
      'meetings': 'Citas',
      'freeDay': 'Free day',
      'freeDayInfo': 'Los días libres no aparecen en la lista de arriba',
      'speaker': 'Formador / Ponente',
      'location': 'Lugar de la formación',
      'requested': 'SOLICITADO',
      'waitlist': 'LISTA DE ESPERA',
      'fullyBooked': 'COMPLETO',
      'edit': 'Editar',
      'webinarSpeaker': 'Ponente',
      'webinar': {
        'host': 'Abrir seminario online',
        'state': {
          'ended': 'El seminario online ha terminado',
          'beforeStart': 'El seminario online empieza a las '
        }
      },
      'sidebar': {
        'price': 'Precio',
        'duration': 'Tiempo de estudio',
        'to': 'hasta',
        'numberOfParticipants': 'Número de participantes',
        'status': 'Estado de la reserva',
        'totalStatus': 'Estado general',
        'contentStatus': 'Estado de la formación'
      },
      'bookingInformation': {
        'title': 'Información de la reserva',
        'note': 'Anotación',
        'accounts': 'Oficina de pagos',
        'invoiceRecipient': 'Receptor de la factura',
        'invoiceAddress': 'Dirección de facturación',
        'food': 'Preferencia sobre la comida',
        'arrival': 'Llegada',
        'arrivalType': {
          'none': 'No especificado',
          'car': 'Coche',
          'train': 'Tren',
          'plane': 'Avión'
        },
        'arrivalDate': 'Fecha de llegada',
        'departure': 'Salida',
        'departureDate': 'Fecha de salida',
        'flightNumber': 'Número de vuelo',
        'trainNumber': 'Número de tren',
        'railwayStation': 'Estación',
        'placeholder': {
          'departure_airport': 'Aeropuerto de salida',
          'destination_airport': 'Aeropuerto de destino',
          'departure_trainstation': 'Estación de salida',
          'destination_trainstation': 'Estación de destino',
          'departure_car': 'Lugar de salida',
          'destination_car': 'Lugar de destino',
          'from': 'de',
          'to': 'a'
        },
        'weekdays': [
          'lun',
          'mar',
          'mié',
          'jue',
          'vie',
          'sab',
          'dom'
        ],
        'months': [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ]
      },
      'reserve': {
        'title': '¿No puede asistir en la/s cita/s?',
        'text': '¡Indique que tiene interés! Le informaremos sobre las posibles nuevas citas.',
        'button': 'Marcar',
        'prebooked': {
          'text': 'Ha marcado que tiene interés en esta formación.'
        }
      },
      'docsAndLinks': 'Documentos y enlaces',
      'links': 'Enlaces',
      'downloads': {
        'modalHeadline': 'Descargas',
        'sidebarLink': 'Documento(s)',
      }
    },
    'course': {
      'type': {
        '0': 'Formación en línea',
        '1': 'Formación en línea',
        '2': 'Formación en línea',
        '3': 'Formación en línea',
        '4': 'Formación en línea',
        '5': 'Formación en línea',
        '6': 'Formación presencial',
        '7': 'Seminario online',
        '-2': 'Ruta de aprendizaje',
        'undefined': '-'
      },
      'status': {
        'open': 'Abierto',
        'sent': 'Sent',
        'passed': 'Apto',
        'sent': 'Enviado',
        'failed': 'No apto',
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        'expired': 'Expirado',
        'booked': 'Reservado',
        'started': 'En proceso',
        'completed': 'Finalizado'
      },
      'warning': {
        'headline': '¡Atención!',
        'body': {
          '1': 'Ha seleccionado un idioma diferente al de la última vez para esta formación.',
          '2': 'Si continúa y hace clic en "Comenzar el entrenamiento", el progreso se está restableciendo.',
        },
        'buttons': {
          'start': 'Comenzar el entrenamiento',
          'startPrevious': 'Seleccione el idioma anterior',
        }
      },
      'statusLabel': {
        'training': 'Formación',
        'content': 'Contenido',
        'attendance': 'Attendance',
        'exam': 'Prueba',
        'survey': 'Encuesta'
      },
      'start': {
        'survey': 'Iniciar encuesta',
        'exam': 'Iniciar prueba'
      },
      'prefix': {
        'category': 'Categoría',
        'state': 'Estado',
        'type': 'Tipo de formación'
      },
      'noAssigned': 'No se le ha asignado ninguna formación.',
      'noSolved': 'Aún no ha completado ninguna formación. ¡Empiece su primera unidad de aprendizaje en el área «Mis formaciones»!',
      'noAssignedBookable': 'No se le ha asignado ninguna formación que se pueda reservar.',
      'noResult': 'No se ha encontrado ninguna formación que cumpla con sus criterios.',
      'loadingFailed': '¡No se han podido cargar los registros de datos solicitados debido a un error desconocido!',
      'noResultInCategory': 'No se ha asignado ninguna formación a esta categoría.',
      'meeting': {
        'noMeeting': 'sin cita',
        'multipleMeetings': 'varias Citas'
      },
      'project': {
        'courses': 'Formación'
      }
    },
    'login': {
      'invalidUser': 'Invalid login data. Username / Password is wrong!',
      'invalidPassword': 'Invalid login data. You have #0 more attempts to enter the password correctly.',
      'accountBlocked': 'Your account has been blocked, please contact a responsible employee.',
      'error': '¡El nombre de usuario y/o la contraseña son incorrectos!',
      'empty': 'Por favor, rellene todos los campos.',
      'back': 'Volver al inicio de sesión',
      'description': 'Indique su nombre de usuario o su dirección de correo electrónico para que le volvamos a mandar sus datos de acceso.',
      'password-reset': {
        'description': 'Por favor, escoja una contraseña nueva para su cuenta de usuario.',
        'success': 'Su contraseña se ha modificado correctamente.',
        'error': 'Las contraseñas indicadas no coinciden.'
      },
      'placeholder': ' Nombre de usuario / Dirección de correo electrónico',
      'button': 'Solicitar datos de acceso',
      'success': 'Su solicitud se ha enviado correctamente.',
      'errorForgot': 'No se ha encontrado ningún usuario que coincida con los datos indicados.',
      'errorForgotSecurityQuestion': '¡Lo sentimos, pero la respuesta es incorrecta!'
    },
    'registration': {
      'description': 'Si todavía no tiene datos de acceso, puede registrarse fácilmente con el código de promoción de la empresa. Inmediatamente después recibirá un correo electrónico.',
      'actionCodeError': 'El código de registro indicado no es válido',
      'actionCodeLimitExceeded': 'Se ha alcanzado el número máximo de registros posibles con el código de promoción indicado.',
      'registerDescription': 'Por favor, rellene el formulario indicado más abajo. En unos minutos recibirá sus datos de acceso por correo electrónico.',
      'title': 'Su registro para la academia',
      'required': 'Campo obligatorio*',
      'emailError': 'La dirección de correo electrónico que introdujo ya está en uso.',
      'send': 'Enviar',
      'completeMessage': '¡Se ha registrado correctamente en la academia!'
    },
    'dataProtection': {
      'accept': 'Aceptar',
      'acceptText': 'Sí, acepto las condiciones de uso/Protección de datos'
    },
    'securityQuestion': {
      'title': 'Pregunta de seguridad',
      'chooseQuestion': 'Por favor, seleccione',
      'questionSelection': 'Por favor, seleccione una pregunta',
      'yourAnswer': 'Por favor, escriba su respuesta',
      'description': 'Por favor, responda a la pregunta de seguridad y escoja una contraseña nueva para su cuenta de usuario.'
    },
    'faq': {
      'title': 'Preguntas frecuentes'
    }
  },
  'sidebar': {
    'all': 'Todos',
    'categories': 'CATEGORÍAS',
    'status': 'ESTADO',
    'courseType': 'TIPO DE FORMACIÓN',
    'topics': 'ÁREAS TEMÁTICAS'
  },
  'placeholder': {
    'username': 'Nombre de usuario',
    'password': 'Contraseña',
    'actionCode': 'Código de promoción'
  },
  'button': {
    'login': 'INICIAR SESIÓN',
    'login-sso': 'SINGLE-SIGN-ON LOGIN',
    'send': 'ENVIAR',
    'start_course': 'INICIAR',
    'changePassword': 'Cambiar contraseña',
    'reset': 'Restablecer',
    'save': 'Guardar',
    'request': 'Consulta',
    'storno': 'Cancelar',
    'canceled': 'Cita cancelada',
    'close': 'Cerrar',
    'booking': 'Reservar',
    'waitlist': 'Lista de espera',
    'start_webinar': 'Comenzar seminario online',
    'open_webinar': 'Abrir seminario online',
    'cancel': 'Cancelar',
    'confirm': 'Confirmar'
  },
  'bookingState': {
    '0': 'SOLICITADO',
    '1': 'RESERVADO',
    '2': 'LISTA DE ESPERA',
    '3': 'CANCELADO',
    '4': 'CANCELADO',
    '5': 'RECHAZADO',
    '6': 'MARCADO PARA RECORDATORIO',
    'onlineTraining': {
      '0': 'SOLICITADO',
      '1': 'RESERVADO',
      '2': 'RECHAZADO'
    }
  },
  'foodPreference': {
    'none': 'Ninguno',
    'lactoseIntolerance': 'Intolerante a la lactosa',
    'vegetarian': 'Vegetariano',
    'vegan': 'Vegano',
    'nutAllergy': 'Alergia a los frutos secos',
    'diabetic': 'Diabetes',
    'glutenFree': 'Sin gluten',
    'kosher': 'Kosher',
    'halal': 'Halal',
    'other': 'Otros'
  },
  'notifications': {
    'error': '¡Algo ha salido mal!',
    'errors': {
      'missingInput': 'Por favor, rellene los campos',
      'imageLoad': 'No se ha podido cargar la imagen solicitada'
    },
    'success': {
      'book': {
        'meeting': 'Ha reservado correctamente la formación presencial.',
        'webinar': 'Ha reservado correctamente el seminario online.',
        'course': 'Ha reservado correctamente la formación.',
        'project': 'Ha reservado correctamente el proyecto.'
      },
      'request': {
        'meeting': 'Ha solicitado correctamente la reserva de la formación presencial.',
        'webinar': 'Ha solicitado correctamente la reserva del seminario online.',
        'course': 'Ha solicitado correctamente la reserva de la formación.',
        'project': 'Ha solicitado correctamente la reserva del proyecto.'
      },
      'profile': {
        'updateSuccess': '¡Su perfil se ha actualizado correctamente!',
        'updatePasswordSuccess': '¡Su contraseña se ha actualizado correctamente!'
      },
      'securityQuestion': {
        'questionSave': '¡Se ha guardado la pregunta de seguridad!'
      }
    }
  },
  'webservice': {
    'error': {
      'duplicate': '¡Ya existe esta reserva!',
      'noaccess': 'Acceso no permitido.',
      'notimplementet': 'Esta función no está disponible en estos momentos.'
    },
    'success': {
      'booking': 'Ha reservado correctamente.',
      'request': 'Ha solicitado correctamente la reserva.',
      'cancel': 'Ha cancelado correctamente. ',
      'waitlist': {
        'add': 'Se le ha añadido a la lista de espera'
      },
      'reservelist': {
        'add': 'Se le ha añadido a la lista de recordatorio'
      }
    },
    'courseCalendar': {
      'noDate': 'Sin fecha límite'
    }
  },
  'trainingmanager': {
    'headline': 'Petición de curso de perfeccionamiento',
    'headlinerequest': 'Realizar solicitud',
    'bottonrequest': 'Realizar solicitud',
    'buttonmanage': 'Gestionar solicitudes',
    'requestform': {
      'requester': 'Solicitante:',
      'date': 'Fecha:',
      'tab01': 'Realizar petición',
      'tab02': 'Petición para grupo/terceros',
      'tab01headline01': 'Nombre del participante:',
      'tab01headline02': 'Terma del curso de perfeccionamiento:',
      'tab01headline04': 'Nombre de los participantes:',
      'tab01headline03': 'Detalles del curso:',
      'tab01label01': 'Tratamiento:',
      'tab01label02': 'Nombre:',
      'tab01label03': 'Apellido:',
      'tab01label04': 'Señor',
      'tab01label05': 'Señora',
      'tab01label06': 'Categoría:',
      'tab01label07': 'Título*:',
      'tab01label08': 'Descripción breve:',
      'tab01label09': 'Otros:',
      'tab01label09O': 'Otros',
      'tab01label10': 'Oficina de pagos:',
      'tab01label11': 'Cita (de/hasta):*',
      'tab01label12': 'Número del curso:',
      'tab01label13': 'Precio:',
      'tab01label14': 'Proveedor:',
      'tab01label15': 'Archivo adjunto:',
      'tab01label16': 'Seleccionar archivo',
      'tab01label17': 'No se ha seleccionado ningún archivo...',
      'tab01label18': 'Enviar solicitud',
      'tab01label19': 'Añadir a otra persona',
      'tab01label20': 'Cancelar',
      'olheadline': 'Añadir participante',
      'olheadline1': 'Nombre del participante',
      'olbodytext': 'blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.',
      'olerror': 'No se ha encontrado ningún participante. Por favor, modifique su parámetro de búsqueda.'
    },
    'manage': {
      'headline': 'Gestionar solicitudes',
      'headlineInfo': 'Consulta de los empleados',
      'bodytextInfo': 'Hinweistext nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.',
      'newRequest': 'Enviar solicitud propia',
      'tableHeadline01': 'Entrada',
      'tableHeadline02': 'Solicitante',
      'tableHeadline03': 'Curso n.º',
      'tableHeadline04': 'Título',
      'tableHeadline05': 'Cita',
      'tableHeadline06': 'Precio',
      'tableMark': 'Marcar todos',
      'buttonapprove': 'Conceder permiso',
      'buttondecline': 'No conceder permiso',
      'modal': {
        'refuse': {
          'headline': 'Mensaje - Rechazar solicitud',
          'bodytext': '',
          'placeholder': 'Por favor, escriba un mensaje...',
          'checkbox': 'Rechazar solicitud sin mensaje',
          'button': 'Enviar',
          'approval': 'Permiso concedido',
          'refuse': 'Permiso no concedido'
        }
      }
    }
  }
};
