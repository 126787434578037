// Slowakisch

module.exports = {
  "main_navigation": {
    "login": "Prihlásenie",
    "registration": "Registrácia",
    "courses": "Moje tréningy",
    "coursesDetail": "Detaily tréningu",
    "catalog": "Katalóg",
    "learningProfile": "Vzdelávací profil",
    "contact": "Kontakt",
    "profile": "Profil",
    "pwLost": "Zabudnuté prihlasovacie údaje?",
    "about": "Impresum",
    "terms_and_conditions": "Podmienky používania / ochrana údajov",
    "logout": "Odhlásiť sa",
    "faq": "Často kladené otázky",
    "trainingmanager": "Žiadosti",
    "trainingmanagerrequest": "Žiadosť",
    "trainingmanagermanage": "Spravovať",
    "trainingmanagermanagedetail": "Detail"
  },
  "general": {
    'offline': {
      'headline': 'Ste offline!',
      'text': 'Táto aplikácia potrebuje na správne fungovanie aktívne internetové pripojenie.',
    },
    "year": "Rok",
    "month": "Mesiac",
    "noResult": "Nebol nájdený žiadny výsledok.",
    "months": {
      "10": "Október",
      "11": "November",
      "12": "December",
      "01": "Január",
      "02": "Február",
      "03": "Marec",
      "04": "Apríl",
      "05": "Máj",
      "06": "Jún",
      "07": "Júl",
      "08": "August",
      "09": "September"
    },
    "category": "Kategória",
    "calendar": "Kalendár",
    "search": "Hľadať",
    "searchError": "Zadajte aspoň tri znaky.",
    "showLess": "Zobraziť menej",
    "showMore": "Zobraziť viac",
    'pagination': {
      'next': 'Ďalšie',
      'prev': 'Predchádzajúci',
      'separator': 'z',
      'pageSize': 'Položky na stránku'
    }
  },
  "maintenance": {
    "title": "Údržbovú práce",
    "text": "Z dôvodu údržbových prác je akadémia momentálne nedostupná. Prosím skúste to neskôr."
  },
  "password": {
    "requirements": {
      "title": "Pravidlá pre heslá",
      "minimalLength": "Heslo musí mať minimálne #0 znakov.",
      "mandantName": "Nesmiete použiť pojem \"#0“.",
      "names": "Heslo nesmie byť krstné meno alebo priezvisko.",
      "userName": "Heslo nesmie obsahovať tvoje prihlasovacie meno.",
      "userNameEqual": "Heslo nesmie obsahovať tvoje prihlasovacie meno.",
      "bigLetters": "Musí obsahovať minimálne #0 veľkých písmen.",
      "smallLetters": "Musí obsahovať minimálne #0 malých písmen.",
      "digits": "Musí obsahovať minimálne #0 čísel.",
      "sameLetters": "Nesmie sa opakovať viac ako #0 znakov za sebou.",
      "oldPassword": "Nové heslo nesmie byť identické so starým heslom.",
      "birthday": "Heslo nesmie byť identické s dátumom narodenia.",
      "minSymbolsPolicy": "Musí obsahovať minimálne #0 špeciálnych znakov.",
      "minPasswordLength": "Heslo musí mať minimálne #0 znakov.",
      "maxPasswordAttempts": "Máte už len #0 pokusov na zadanie správneho hesla."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Je nám ľúto!",
      "text1": "Požadovaná stránka nie je dostupná.",
      "text2": "Aby ste sa vrátili na domovskú stránku použite tlačidlo späť na Vašom prehliadači.",
      "button": "Späť na domovskú stránku."
    },
    "userNotFound": {
      "headline": "Pri prihlásení sa vyskytla chyba!",
      "text": "Používateľ organizácie nebol nájdený. Skúste sa prihlásiť neskôr alebo kontaktujte Vašu kontaktnú osobu alebo nadriadeného.",
      "button": "Skúsiť znova"
    },
    "courseCalendar": {
      "expired": "Vypršal",
      "soonExpired": "Onedlho vyprší",
      'expiredMeetings': 'Minulé dátumy'
    },
    "profile": {
      "userdata": "Používateľské údaje",
      "password": "Heslo",
      "genderLab": "Oslovenie",
      "gender": {
        "men": "Pán",
        "woman": "Pani",
        "diverse": "Rôzne",
        "unknown": "Žiadna informácia"
      },
      "firstname": "Krstné meno",
      "lastname": "Priezvisko",
      "email": "Email",
      "strasse": "Ulica / č.",
      "city": "PSČ / Mesto",
      "phone": "Telefón",
      "fax": "Fax",
      "position": "Pozícia",
      "department": "Oddelenie",
      "required": "Povinné pole",
      "oldPassword": "Staré heslo",
      "newPassword": "Nové heslo",
      "newPasswordRep": "Zopakovať",
      "company": "Firma",
      "language": "Jazyk"
    },
    "catalog": {
      "back": "Späť",
      "bookingDialog": {
        "notice": "Tu zadajte odôvodnenie žiadosti o termín"
      }
    },
    "contact": {
      "info": "Prosím zadajte Vašu žiadosť do poľa pre správy. Ak sa odvolávate na špecifický tréning, prosím zadajte jeho názov: Tak môže byť Vaša správa priamo posunutá na správne miesto.",
      "trainingSelection": "Výber tréningov",
      "yourMessage": "Vaša správa",
      "success": "Vaša správa bola úspešne odoslaná!",
      "otherReason": "Všeobecná žiadosť o kontakt.",
      "otherReasonSubject": "Iná žiadosť",
      "chooseReason": "Prosím zvoľte"
    },
    "learningProfile": {
      "title": "Vzdelávací profil",
      "unit": "Lekcie",
      "points": "Celkový počet bodov",
      "history": "História štúdia",
      "restoreFilter": "Vymazať filter",
      "certificate": "Certifikát",
      "downloadAll": "Stiahnuť všetky",
      "withoutCategory": "Bez kategórie",
      "tooltip01": "Máte",
      "tooltip02": "z",
      "tooltip03": "Absolvované z lekcií.",
      "gamification": {
        "badge": {
          "title": "Vynikajúce",
          "congratulations": "Blahoželáme!",
          "achieved": "Získali ste nové vyznamenanie!"
        }
      }
    },
    "courseDetail": {
      "title": "Detaily tréningu",
      "back": "Späť k zoznamu",
      "progress": "Pokrok",
      "expiry": "platné do",
      "classroomNo": "Číslo školenia",
      "contactPerson": "Kontaktná osoba",
      "targetGroup": "Cieľová skupina",
      "bonusPoints": "Bonusové body",
      "classroomContent": "Obsah školenia",
      "webinarLight": "Informácie k webináru",
      "specials": "Osobitnosti",
      "stornoConditions": "Podmienky stornovania",
      "meeting": "Termín",
      "meetings": "Termíny",
      'freeDay': 'Free day',
      'freeDayInfo': 'Voľné dni nie sú uvedené vyššie\n',
      "speaker": "Tréner / Referent",
      "location": "Miesto školenia",
      "requested": "ŽIADANÉ",
      "waitlist": "ČAKACIA LISTINA",
      "fullyBooked": "REZERVOVANÉ",
      "edit": "Spracovať",
      "webinarSpeaker": "Referent",
      "docsAndLinks": "Dokumenty a linky",
      "links": "Linky",
      "webinar": {
        "host": "Otvoriť webinár",
        "state": {
          "ended": "Webinár je ukončený",
          "beforeStart": "Webinár začne "
        }
      },
      "sidebar": {
        "price": "Cena p.P.",
        "duration": "Dĺžka výučby",
        "to": "do",
        "numberOfParticipants": "Počet účastníkov",
        "status": "Status rezervácie",
        'totalStatus': 'Celkový stav',
        'contentStatus': 'Stav odbornej prípravy'
      },
      "bookingInformation": {
        "title": "Informácie o rezervácii",
        "note": "Komentár",
        "accounts": "Cenové centrum",
        "invoiceRecipient": "Príjemca faktúry",
        "invoiceAddress": "Fakturačná adresa",
        "food": "Preferencia stravovania",
        "arrival": "Príchod",
        "arrivalType": {
          "none": "Žiadna informácia",
          "car": "Auto",
          "train": "Vlak",
          "plane": "Letecky"
        },
        "arrivalDate": "Dátum príchodu",
        "departure": "Odchodu",
        "departureDate": "Dátum odchodu",
        "flightNumber": "Číslo letu",
        "trainNumber": "Číslo vlaku",
        "railwayStation": "Železničná stanica",
        'placeholder': {
          'departure_airport': 'Letisko odletu',
          'destination_airport': 'Cieľové letisko',
          'departure_trainstation': 'Odchodová stanica',
          'destination_trainstation': 'Cieľová železničná stanica',
          'departure_car': 'Miesto odchodu',
          'destination_car': 'Miesto určenia',
          'from': 'z adresy',
          'to': 'na'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          "Január",
          "Február",
          "Marec",
          "Apríl",
          "Máj",
          "Jún",
          "Júl",
          "August",
          "September",
          "Október",
          "November",
          "December"
        ]
      },
      "reserve": {
        "title": "Neviete sa zúčastniť termínu/ov?",
        "text": "Poznačte váš záujem! Budete informovaní o možných nových termínoch.",
        "button": "Poznačiť",
        "prebooked": {
          "text": "Poznačili ste Váš záujem o tréning."
        }
      },
      'downloads': {
        'modalHeadline': 'Na stiahnutie',
        'sidebarLink': 'Dokument(y)',
      }
    },
    "course": {
      "type": {
        "0": "Online tréning",
        "1": "Online tréning",
        "2": "Online tréning",
        "3": "Online tréning",
        "4": "Online tréning",
        "5": "Online tréning",
        "6": "Prezenčný tréning",
        "7": "Webinár",
        "-2": "Projekt",
        "undefined": "-"
      },
      "status": {
        "open": "Otvorený",
        'sent': 'Sent',
        "passed": "Absolvoval",
        'sent': 'Odoslané',
        "failed": "Neabsolvoval",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Vypršal",
        "booked": "Rezervovaný",
        "started": "Spracúva sa",
        "upcoming": "Blížiaci sa",
        "running": "Prebieha",
        "completed": "Ukončený",
        "notyet": "Zatiaľ nedostupný"
      },
      "notification": {
        "success1": "Úspešne ste absolvovali ",
        "success2": " tréning!"
      },
      'warning': {
        'headline': 'Pozor!',
        'body': {
          '1': 'Pre toto školenie ste si vybrali iný jazyk ako naposledy.',
          '2': 'Ak pokračujete a kliknete na "Spustiť školenie", postup sa vynuluje.',
        },
        'buttons': {
          'start': 'Spustiť školenie',
          'startPrevious': 'Výber predchádzajúceho jazyka',
        }
      },
      "statusLabel": {
        "training": "Tréning",
        'content': 'Content',
        'attendance': 'Attendance',
        "exam": "Skúška",
        "survey": "Prieskum",
        "efficacyControl": "Kontrola efektívnosti"
      },
      "start": {
        "survey": "Spustiť prieskum",
        "exam": "Spustiť skúšku",
        "efficacyControl": "Spustiť kontrolu efektívnosti"
      },
      "prefix": {
        "category": "Kategória",
        "state": "Status",
        "type": "Typ tréningu"
      },
      "noAssigned": "Nemáte pridelený žiadne tréningy.",
      "noSolved": "Neabsolvovali ste ešte žiadne tréningy. Prejdite do časti „Moje tréningy“ a začnite s lekciami!",
      "noAssignedBookable": "Neboli Vám pridelené žiadne tréningy, na ktoré sa môžete registrovať.",
      "noResult": "Nenašil sa žiadne tréningy, ktoré zodpovedajú kritériám.",
      "loadingFailed": "Súbory nebolo možné načítať z dôvodu neznámej chyby.",
      "noResultInCategory": "Tejto kategórii neboli ešte pridelené žiadne tréningy.",
      "meeting": {
        "noMeeting": "žiaden termín",
        "multipleMeetings": "rôz. Termíny"
      },
      "project": {
        "courses": "Tréningy"
      }
    },
    "login": {
      "invalidUsername": "Neplatné prihlásenie Nesprávne meno používateľa!",
      "invalidUser": "Neplatné prihlásenie Nesprávne používateľské meno / heslo!",
      "invalidPassword": "Neplatné prihlásenie Máte už len #0 pokusov na zadanie správneho hesla.",
      "accountBlocked": "Vaše konto bolo zablokované Prosím obráťte sa na príslušných zamestnancov.",
      "error": "Nesprávne Používateľské meno a/alebo heslo!",
      "empty": "Prosím vyplňte nasledujúce polia.",
      "back": "Späť k prihláseniu",
      "description": "Prosím zadajte Vaše používateľské meno alebo emailovú adresu pre opätovné zaslanie prihlasovacích údajov.",
      "password-reset": {
        "description": "Prosím zadajte nové heslo pre Váš účet.",
        "success": "Vaše heslo bolo úspešne zmenené.",
        "error": "Uvedené heslá sa nezhodujú."
      },
      "placeholder": " Používateľské meno / Emailová adresa",
      "button": "Požiadať o prihlasovacie údaje",
      "success": "Žiadosť úspešne odoslaná.",
      "errorForgot": "Nenašiel sa žiaden používateľ, ktorý zodpovedá kritériám.",
      "errorForgotSecurityQuestion": "Vaša odpoveď je žiaľ nesprávna!"
    },
    "registration": {
      "description": "Ak ešte nemáte prihlasovacie údaje, môžete sa registrovať pomocou akciového kódu Vašej firmy. Po registrácii Vám bude zaslaný email.",
      "actionCodeError": "Zadaný registračný kód je neplatný.",
      "actionCodeLimitExceeded": "Maximálny počet registrácií pre akciový kód je vyčerpaný.",
      "registerDescription": "Prosím vyplňte formulár dolu. Vaše prístupové údaje Vám budú zaslané emailom.",
      "title": "Vaša registrácia na akadémiu",
      "required": "Povinné pole*",
      "emailError": "Zadaná emailová adresa sa už používa.",
      "emailErrorNotAllowed": "Registrácia s touto emailovou adresou nie je možná.",
      "send": "Odoslať",
      "completeMessage": "Úspešne ste sa registrovali na akadémiu!"
    },
    "dataProtection": {
      "accept": "Súhlasím",
      "acceptText": "Áno, súhlasím s podmienkami používania / ochrany údajov"
    },
    "securityQuestion": {
      "title": "Bezpečnostná otázka",
      "chooseQuestion": "Prosím zvoľte",
      "questionSelection": "Prosím zvoľte otázku",
      "yourAnswer": "Prosím uveďte odpoveď",
      "description": "Prosím zodpovedajte bezpečnostnú otázku a zadajte nové heslo pre Váš účet."
    },
    "faq": {
      "title": "Často kladené otázky"
    }
  },
  "sidebar": {
    "all": "Všetky",
    "categories": "KATEGÓRIE",
    "status": "STATUS",
    "courseType": "TYP TRÉNINGU",
    "topics": "TEMATICKÉ OKRUHY"
  },
  "placeholder": {
    "username": "Používateľské meno",
    "password": "Heslo",
    "actionCode": "Akciový kód"
  },
  "button": {
    "login": "PRIHLÁSIŤ",
    "login-sso": "ACTIVE-DIRECTORY LOGIN",
    "send": "ODOSLAŤ",
    "start_course": "ŠTART",
    "changePassword": "Zmeniť heslo",
    "reset": "Vynulovať",
    "save": "Uložiť",
    "request": "Žiadosť",
    "storno": "Stornovať",
    "canceled": "Stornovať termín",
    "close": "Zatvoriť",
    "booking": "Rezervovať",
    "waitlist": "Čakacia listina",
    "start_webinar": "Spustiť webinár",
    "open_webinar": "Otvoriť webinár",
    'cancel': 'Zrušiť',
    'confirm': 'Potvrďte'
  },
  "bookingState": {
    "0": "ŽIADANÉ",
    "1": "REZERVOVANÉ",
    "2": "ČAKACIA LISTINA",
    "3": "STORNOVANÉ",
    "4": "STORNOVANÉ",
    "5": "ODMIETNUTÉ",
    "6": "POZNAČENÉ",
    "onlineTraining": {
      "0": "ŽIADANÉ",
      "1": "REZERVOVANÉ",
      "2": "ODMIETNUTÉ"
    }
  },
  "foodPreference": {
    "none": "Žiadne",
    "lactoseIntolerance": "Laktózová intolernacia",
    "vegetarian": "Vegetariánske",
    "vegan": "Vegánske",
    "nutAllergy": "Alergie na orechy",
    "diabetic": "Diabetes",
    "glutenFree": "Bezlepkový",
    "kosher": "Kóšer",
    "halal": "Halal",
    "other": "Iné"
  },
  "notifications": {
    "error": "Niečo sa pokazilo!",
    "errors": {
      "missingInput": "Prosím vyplňte polia",
      "imageLoad": "Obrázok nebolo možné načítať"
    },
    "success": {
      "book": {
        "meeting": "Úspešne ste rezervovali prezenčný tréning.",
        "webinar": "Úspešne ste rezervovali webinár.",
        "course": "Úspešne ste rezervovali tréning.",
        "project": "Úspešne ste rezervovali projekt."
      },
      "request": {
        "meeting": "Úspešne ste požiadali o prezenčný tréning.",
        "webinar": "Úspešne ste požiadali o webinár.",
        "course": "Úspešne ste požiadali o tréning.",
        "project": "Úspešne ste požiadali o projekt."
      },
      "profile": {
        "updateSuccess": "Váš profil bol úspešne aktualizovaný!",
        "updatePasswordSuccess": "Vaše heslo bolo úspešne aktualizované!"
      },
      "securityQuestion": {
        "questionSave": "Vaša bezpečnostná otázka bola úspešne uložená!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Rezervácia už existuje!",
      "noaccess": "Nepovolený prístup.",
      "notimplementet": "Táto funkcia momentálne nie je dostupná."
    },
    "success": {
      "booking": "Rezervácia prebehla úspešne",
      "request": "Žiadosť prebehla úspešne.",
      "cancel": "Stornovanie prebehlo úspešne. ",
      "waitlist": {
        "add": "Ste úspešne pridaný na čakaciu listinu."
      },
      "reservelist": {
        "add": "Ste úspešne pridaný na záujmovú listinu."
      }
    },
    "courseCalendar": {
      "noDate": "Bez lehoty"
    }
  },
  "trainingmanager": {
    "headline": "Žiadosť o ďalšie vzdelávanie",
    "headlinerequest": "Podať žiadosť",
    "bottonrequest": "Podať žiadosť",
    "buttonmanage": "Spravovať žiadosti",
    "requestform": {
      "requester": "Podávateľ žiadosti:",
      "date": "Dátum:",
      "tab01": "Vytvoriť požiadavku",
      "tab02": "Požiadavka pre Skupinu/Tretie osoby",
      "tab01headline01": "Meno účastníka:",
      "tab01headline02": "Téma ďalšieho vzdelávania:",
      "tab01headline04": "Mená účastníkov:",
      "tab01headline03": "Detaily kurzu:",
      "tab01label01": "Oslovenie:",
      "tab01label02": "Krstné meno:",
      "tab01label03": "Priezvisko:",
      "tab01label04": "Pán",
      "tab01label05": "Pani",
      "tab01label06": "Kategória:",
      "tab01label07": "Titul*:",
      "tab01label08": "Krátky popis:",
      "tab01label09": "Iné:",
      "tab01label09O": "Iné",
      "tab01label10": "Cenové centrum",
      "tab01label11": "Termín (od/do):*",
      "tab01label12": "Číslo kurzu:",
      "tab01label13": "Cena:",
      "tab01label14": "Poskytovateľ:",
      "tab01label15": "Príloha:",
      "tab01label16": "Vybrať súbor:",
      "tab01label17": "Neboli vybrané žiadne súbory...",
      "tab01label18": "Odoslať žiadosť",
      "tab01label19": "Pridať ďalšie osoby",
      "tab01label20": "Vyúčtovať",
      "olheadline": "Pridať účastníkov",
      "olheadline1": "Meno účastníka",
      "olbodytext": "Potom zvoľte mená účastníkov tréningu.",
      "olerror": "Nenašli sa žiadny účastníci. Prosím zmeňte parametre vyhľadávania."
    },
    "manage": {
      "headline": "Spravovať žiadosti",
      "headlineInfo": "Otázky zamestnancov",
      "bodytextInfo": "Následne nájdete všetky žiadosti o ďalšie vzdelávanie so žiadosťou o spracovanie.",
      "newRequest": "Odoslať vlastnú žiadosť",
      "tableHeadline01": "Prijaté správy",
      "tableHeadline02": "Žiadateľ",
      "tableHeadline03": "Č. Kurzu",
      "tableHeadline04": "Názov",
      "tableHeadline05": "Termín",
      "tableHeadline06": "Cena",
      "tableMark": "Označiť všetky",
      "buttonapprove": "Udeliť povolenie",
      "buttondecline": "Neudeliť povolenie",
      "modal": {
        "refuse": {
          "headline": "Správa - Odmietnuť žiadosť",
          "bodytext": "",
          "placeholder": "Prosím zadajte text....",
          "checkbox": "Odmietnuť žiadosť bez správy",
          "button": "Odoslať",
          "approval": "Povolenie udelené",
          "refuse": "Povolenie neudelené"
        }
      }
    }
  }
}
