import CustomizingLoader from '@/utils/CustomizingLoader';
import Auth from '@/middleware/auth';
import { lazyLoading } from '@/utils/helpers';

export const Course = {
  path: '/courses',
  component: () => CustomizingLoader('views/layout/pages/Layout'),
  showInMenu: true,
  meta: {
    label: 'main_navigation.courses',
    middleware: Auth
  },
  children: [{
    path: '',
    name: 'courses',
    component: lazyLoading('courses'),
    meta: {
      label: 'main_navigation.courses',
      middleware: Auth
    }
  }, {
    path: 'calendar',
    name: 'coursesCalendar',
    component: lazyLoading('courses', 'Calendar'),
    meta: {
      middleware: Auth
    }
  }]
};
