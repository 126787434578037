import { TokenValidator } from 'am-api-connector-general';
import configs from '@/config';

export default function auth({ next, router }) {
  return TokenValidator.isValid(configs.host + '/academies/api', () => {}).then(result => {
    if (result === false) {
      if (router.history.current && router.history.current.query && router.history.current.query.ref) {
        window.location = window.location.origin + '/?ref=' + router.history.current.query.ref;
      } else {
        // Don't use vue router because we need to trigger SSO if it is enabled for the customer this only happens on a reload.
        window.location = window.location.origin + '/';
      }
    } else {
      next();
    }
  });
}
