<template>
  <div class="content-maintenance">
    <div class="bgImg"></div>
    <div class="mainBox">
      <h1 class="headline-maintenance"><p class="maintenance">{{$t('maintenance.title')}}</p></h1>
      <div class="infoText-maintenance">
        <p>{{$t('maintenance.text')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getBrowserLanguage } from '../../utils/LanguageDeterminer';

export default {
  name: 'Maintenance',
  mounted() {
    if (!this.$store.state.userStore.language) {
      this.$store.dispatch('userStore/setLanguage', getBrowserLanguage('de', ['de', 'en']));
    }
  }
};
</script>
<style scoped>
  ::-moz-selection {
    color: #fff; background: #f28f02 !important;
  }

  ::selection {
    color: #fff; background: #f28f02 !important;
  }
</style>
