import config from '../config';

export function getBrowserLanguage(masterLanguage, supportedLanguages) {
  const browserLanguage = navigator.language.substring(0, 2);

  supportedLanguages = supportedLanguages || ['de', 'en'];
  let language = masterLanguage || config.defaultLanguage;

  if (browserLanguage && supportedLanguages.includes(browserLanguage.toLowerCase())) {
    language = browserLanguage.toLowerCase();
  }

  return language;
}
