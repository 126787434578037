import CustomizingLoader from '@/utils/CustomizingLoader';
import Auth from '@/middleware/auth';
import { lazyLoading } from '@/utils/helpers';

export const Profile = {
  path: '/profile',
  component: () => CustomizingLoader('views/layout/pages/Layout'),
  showInMenu: false,
  meta: {
    label: 'main_navigation.profile',
    middleware: Auth
  },
  children: [{
    path: '',
    name: 'profile',
    component: lazyLoading('profile'),
    meta: {
      label: 'main_navigation.profile',
      middleware: Auth
    }
  }]
};
